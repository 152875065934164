<template>
  <div id="add-sort">
    <!-- 物品分类 -->
    <div class="con">
      <edit-value
        :editValue="data"
        @showEditValueData="showEditValueData"
        @beforeAvatarUpload="beforeAvatarUpload"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></edit-value>
      <div class="buts">
        <div class="but" @click="makeSure">确定</div>
        <div class="but" @click="cancel">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as ItemCollectionData from "./ItemCollection.js";
import { getStoreItemOneById, addOrUpdateStoreItemOne } from "@/api/ItemCollection/ItemCollection.js";

export default {
  data() {
    return {
      data: ItemCollectionData.editBaseData(),
      id: "",
    };
  },
  methods: {
    async initData(){
      let requestData = {
        id: this.id,
      };
      let resultData = await getStoreItemOneById(requestData)
      this.data = this.$total.json2array(this.data,resultData)
    },
    async makeSure() {
      let requestData = {
        id: this.id,
      };
      let mes = this.$total.judegArrayNull(this.data);
      if (mes != "ok") {
        this.$message({
          message: mes + "不可为空!",
          type: "error",
        });
        return;
      }
      requestData = this.$total.array2json(this.data,requestData)
      await addOrUpdateStoreItemOne(requestData);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      this.cancel();
    },
    cancel() {
      this.$store.dispatch("tagsView/delView", this.$router.currentRoute);
      this.$router.go(-1);
    },
    beforeAvatarUpload(val) {},
    handleAvatarSuccess(val) {
      console.log(val);
      if (val.code == "200") {
        let resultData = val.result;
        this.data.forEach((element) => {
          if (element.key == resultData.key) {
            element.value = resultData.path;
          }
        });
      }
    },
    showEditValueData(val) {
      this.data = val;
    },
  },
 async mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : "";
    console.log(this.id);
    
    if (this.id != '') {
      await this.initData()
    }
  },
};
</script>

<style lang="less" scoped>
#add-sort {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  > .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;
    > .buts {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      margin-top: 10px;
      > .but {
        width: auto;
        background-color: #3752ff;
        color: #fff;
        padding: 5px 60px;
        margin: 0 20px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>