<template>
  <div id="login">
    <div class="con">
      <div class="left"></div>
      <div class="right">
        <div class="top">
          <div class="tt">登录</div>
        </div>
        <div class="line">
          <el-input
            v-model="loginName"
            clearable
            placeholder="请输入用户名"
          ><i slot="prefix" class="el-input__icon el-icon-user"></i></el-input>
        </div>
        <div class="line">
          <el-input
            v-model="password"
            clearable
            show-password
            placeholder="请输入密码"
          ><i slot="prefix" class="el-input__icon el-icon-lock"></i></el-input>
        </div>
        <div class="but" @click="login">登录</div>
      </div>
    </div>

    <div  class="bottom-copyright div-flex-center">{{ this.$copyright }}</div>
  </div>
</template>

<script>
import { login } from "@/api/login/login.js";
export default {
  data() {
    return {
      loginName: process.env.VUE_APP_USER,
      password: process.env.VUE_APP_PSW,
    };
  },
  methods: {
    async login() {
      let requestData = {
        loginName: this.loginName,
        password: this.password,
      };
      let resultData = await login(requestData);
      localStorage.setItem("isToken", resultData.token);
      localStorage.setItem("userName", resultData.userName);
      let tag = {
        path: "/elderly",
        fullPath: "/elderly",
        query: {},
        name: "老人管理",
        meta: {
          title: "老人管理",
          activeMenu: "老人管理",
        },
      };
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push("/elderly");
    },
  },
  mounted() {
    this.$store.dispatch("tagsView/delAllViews");
  },
};
</script>

<style lang="less" scoped>
#login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../assets/login.png');
  background-size: 100% 100%;
  > .con {
    width: 70%;
    height: 70%;
    display: flex;
      background-color: #E8EFF9;
      border-radius: 13px;
    > .left {
      width: 0;
      flex: 1;
      height: 100%;
      background: url('../assets/login2.png');
      background-size: auto 100%;
    }
    > .right {
      width: 20vw;
      height: 100%;
      border-radius: 13px;
      border: 1px solid #f0f0f0;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      align-items: center;
      // background:linear-gradient(to bottom, #a3d2ff, #d8e4ff) ;
      >.top{
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        >.tt{
          font-size: 24px;
          border-bottom: 3px solid #e7e7e7;
          padding-bottom: 4px;
          font-weight: 600;
          letter-spacing: 2px;
          box-sizing: border-box;
        }
      }
      >.line{
        width: 80%;
        height: 50px;
        margin-bottom: 18px;
        /deep/.el-input__inner{
          color: #0c1431;
          background-color: #4e74ff00;
          border-color: #4e74ff00;
          border-radius: 0;
          border-bottom: 2px solid #0c1431;
        }
        /deep/.el-input__icon{
          color: #0c1431;
        }
      }
      >.but{
        width: 80%;
        height: 40px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #4e73ff;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
      }
    }
  }


}
</style>