import request from "@/utils/request";
let baseUrl = "/nursinghomes/user/";

/**
 * @description: 获取用户列表
 * @return {*}
 */
export async function getUserListByPage(data) {
  return await request({
    url: baseUrl + "getUserListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 增加或修改单条用户信息
 * @return {*}
 */
export async function addOrUpdateUserOne(data) {
  return await request({
    url: baseUrl + "addOrUpdateUserOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除员工信息
 * @return {*}
 */
export async function delUserList(data) {
  return await request({
    url: baseUrl + "delUserList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}