import request from "@/utils/request";
let baseUrl = "/nursinghomes/storeItemApplication/";

/**
 * @description: 分页查询物品申请列表
 * @return {*}
 */
export async function getStoreItemApplicationListByPage(data) {
  return await request({
    url: baseUrl + "getStoreItemApplicationListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 修改申请状态
 * @return {*}
 */
export async function addOrUpdateStoreItemApplicationOne(data) {
  return await request({
    url: baseUrl + "addOrUpdateStoreItemApplicationOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
