<template>
  <div id="elderly-index">
    <!-- 物品领取 -->
    <div class="search">
      <top-search
        :searchData="searchData"
        :searchButton="searchButton"
        @showSearchData="showSearchData"
        @handleClick="handleSearchClick"
      ></top-search>
    </div>
    <div class="content">
      <div class="table">
        <diy-table
          :tableData="tableData"
          :tableMenu="tableMenu"
          :model="model"
          :width="'360'"
          @showTableData="showTableData"
          @handleClick="handleTableClick"
        ></diy-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as ItemRecordData from "./ItemRecord.js";
import {
  getStoreItemApplicationListByPage,
  addOrUpdateStoreItemApplicationOne,
} from "@/api/ItemRecord/ItemRecord.js";
export default {
  data() {
    return {
      searchData: ItemRecordData.baseData().searchData,
      searchButton: ItemRecordData.baseData().searchButton,
      tableData: [],
      tableMenu: ItemRecordData.baseData().menuData,
      model: "ItemRecord",
      pageData: {
        currentPage: 1,
        total: 3000,
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        page: 1, // 当前页码
      },
    };
  },
  methods: {
    /**
     * 初始化界面数据
     */
    async initTable() {
      let requestData = {
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.currentPage,
      };
      requestData = this.$total.array2json(this.searchData, requestData);
      let resultData = await getStoreItemApplicationListByPage(requestData);
      this.pageData.total = resultData.total;
      resultData.list.forEach((element) => {
        this.$set(
          element,
          "tableButton",
          ItemRecordData.baseData().tableButton
        );
      });
      this.tableData = resultData.list;
    },
    /**
     * 顶部搜索界面的按钮事件
     * @param key
     */
    handleSearchClick(key) {
      switch (key) {
        case "search":
          // 查询(默认所有界面均有)
          this.pageData.currentPage = 1;
          this.initTable();
          break;
        default:
          break;
      }
    },
    jugeEditPage(query, title, path) {
      let tag = this.$base.returnTag(title, query, path, path, "物品分类");
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push({
        path: tag.path,
        query: query,
      });
    },
    /**
     * 表格中按钮点击事件
     * @param val
     */
    async handleTableClick(val) {
      let row = val.row;
      let key = val.key;
      let requestData = {};
      let resultData = {};
      switch (key) {
        case "ratify":
          requestData = { id: row.id };
          requestData.status = "已批准";
          await addOrUpdateStoreItemApplicationOne(requestData);
          this.$message({
            type: "success",
            message: "已批准!",
          });
          await this.initTable();
          break;
        case "reject":
          // 拒绝
          requestData = { id: row.id };
          requestData.status = "已拒绝";
          await addOrUpdateStoreItemApplicationOne(requestData);
          this.$message({
            type: "success",
            message: "已拒绝!",
          });
          await this.initTable();
          break;
        default:
          break;
      }
    },
    openDialog(key, data) {},
    dialogOper(key) {},

    showTableData(val) {
      this.tableData = val;
    },
    showSearchData(val) {
      this.searchData = val;
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTable();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTable();
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  async mounted() {
    await this.initTable();
  },
};
</script>

<style lang="less" scoped>
#elderly-index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;

  > .search {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    min-height: 80px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    > .table {
      width: 100%;
      flex: 1;
      height: 0;
    }
    > .page {
      width: 100%;
      height: 50px;
    }
  }
}
</style>
