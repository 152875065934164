<template>
  <div id="questionnaireRecord">
    <!-- 老人问卷调查 -->
    <div class="con">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基础情况调查" name="first"></el-tab-pane>
        <el-tab-pane label="入院初始问卷" name="second"></el-tab-pane>
      </el-tabs>
      <div class="cc customScrollBar" v-if="activeName == 'first'">
        <div class="item" v-for="item in questions">
          <div class="title">
            <div class="va">
              {{ item.question }}
              <span>{{ item.questionRemark }}</span>
            </div>
          </div>
          <div class="val">
            <div class="radio-input" v-if="item.questionType == 'selectOne'">
              <div class="to">
                <el-radio-group v-model="item.answer">
                  <el-radio :label="op.name" v-for="op in item.optionsAnswer">{{
                    op.name
                  }}</el-radio>
                </el-radio-group>
              </div>
              <div class="bu">
                <div class="ti">备注:</div>
                <div class="va">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入备注"
                    v-model="item.answerRemark"
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <div class="radio-input" v-if="item.questionType == 'selectMore'">
              <div class="to">
                <el-checkbox-group v-model="item.answer">
                  <el-checkbox
                    :label="op.name"
                    v-for="op in item.optionsAnswer"
                  ></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="bu">
                <div class="ti">备注:</div>
                <div class="va">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入备注"
                    v-model="item.answerRemark"
                  >
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cc" v-if="activeName == 'second'">
        <div class="cl">
          <div class="line">
            <div class="title">老人姓名:</div>
            <div class="val">{{ questionsData.elderlyName }}</div>
          </div>
          <div class="line">
            <div class="title">初始评分:</div>
            <div class="val">
              <span v-if="questionsData.status != 'y'">暂无评分</span
              ><span v-else>{{ questionsData.score }} 分</span>
            </div>
          </div>
          <div class="line">
            <div class="title">评估结果:</div>
            <div class="val">
              <span v-if="questionsData.status != 'y'">暂无评分</span
              ><span v-else>{{ questionsData.appraise }}</span>
            </div>
          </div>
        </div>
        <div class="cco customScrollBar">
          <div
            class="item1"
            v-for="queItem in questionsData.content.questionsList"
          >
            <div class="titleName">{{ queItem.titleName }}</div>
            <div class="item" v-for="item in queItem.questions">
              <div class="title">
                <div class="va">
                  {{ item.question }}
                  <span>{{ item.questionRemark }}</span>
                </div>
              </div>
              <div class="val">
                <div
                  class="radio-input"
                  v-if="item.questionType == 'selectOne'"
                >
                  <div class="to">
                    <el-radio-group v-model="item.answer">
                      <el-radio
                        :label="op.name"
                        v-for="op in item.optionsAnswer"
                        >{{ op.name }}</el-radio
                      >
                    </el-radio-group>
                  </div>
                  <div class="bu">
                    <div class="ti">备注:</div>
                    <div class="va">
                      <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入备注"
                        v-model="item.answerRemark"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
                <div
                  class="radio-input"
                  v-if="item.questionType == 'selectMore'"
                >
                  <div class="to">
                    <el-checkbox-group v-model="item.answer">
                      <el-checkbox
                        :label="op.name"
                        v-for="op in item.optionsAnswer"
                      ></el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="bu">
                    <div class="ti">备注:</div>
                    <div class="va">
                      <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入备注"
                        v-model="item.answerRemark"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buts">
        <div
          class="but"
          @click="makeSure"
          v-if="!(activeName == 'second' && questionsData.status == 'y')"
        >
          确定
        </div>
        <div class="but" @click="cancel">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createAndGetBaseQuestionnaireRecordOne,
  createAndGetInitQuestionnaireRecordOne,
  saveAndComputeScore,
} from "@/api/questionnaireRecord/questionnaireRecord.js";
export default {
  data() {
    return {
      activeName: "first",
      elderlyId: "",
      id: "", // 问卷ID
      elderlyName: "", // 老人姓名
      tempData: {}, // 用来最后的数据保存

      questions: [],

      questionsData: {
        // 入院初始问卷
        status: "w", // 问卷状态  w 为提交   y 已提交
        score: "", // 最终评分
        elderlyName: "", // 老人姓名
        appraise: "", // 和评分对应的评语
        content: {
          // 问卷内容
          id: "",
          questionsList: [],
        },
      },
    };
  },
  methods: {
    /**
     * 初始化老人基础信息
     */
    async initData() {
      let requestData = {
        elderlyId: this.elderlyId,
      };
      let resultData = await createAndGetBaseQuestionnaireRecordOne(
        requestData
      );
      this.id = resultData.id;
      this.tempData = JSON.parse(JSON.stringify(resultData));
      this.questions = JSON.parse(
        JSON.stringify(resultData.content.questionsList[0].questions)
      );
      this.questions = this.$base.changeQuestionnaireRecord2Page(
        this.questions
      );
    },
    /**
     * 初始化入院初始评分
     */
    async initData1() {
      let requestData = {
        elderlyId: this.elderlyId,
      };
      let resultData = await createAndGetInitQuestionnaireRecordOne(
        requestData
      );
      this.id = resultData.id;
      this.questionsData = JSON.parse(JSON.stringify(resultData));
      console.log(this.questionsData);
      this.questionsData.content.questionsList.forEach((element) => {
        element.questions = this.$base.changeQuestionnaireRecord2Page(
          element.questions
        );
      });
    },
    /**
     * 问卷提交
     */
    async makeSure() {
      switch (this.activeName) {
        case "first":
          this.tempData.content.questionsList[0].questions = this.questions;
          this.submintBase(this.tempData);
          break;
        case "second":
          this.submintBase(this.questionsData);
          break;

        default:
          break;
      }
    },
    /**
     * 提交初始问卷
     */
    async submintBase(data) {
      data.content.questionsList.forEach((element) => {
        element.questions = this.$base.changeQuestionnaireRecord2Interface(
          element.questions
        );
      });
      // this.tempData.content.questionsList[0].questions =
      //   this.$base.changeQuestionnaireRecord2Interface(this.questions);
      // let requestData = this.tempData;
      await saveAndComputeScore(data);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      this.cancel();
    },
    cancel() {
      this.$store.dispatch("tagsView/delView", this.$router.currentRoute);
      this.$router.go(-1);
    },
    async handleClick(tab, event) {
      switch (this.activeName) {
        case "first":
          // 基础情况调查
          await this.initData();
          break;
        case "second":
          // 入院初始问卷
          await this.initData1();
          break;

        default:
          break;
      }
    },
  },
  async mounted() {
    this.elderlyId = this.$route.query.id ? this.$route.query.id : "";
    await this.initData();
  },
};
</script>

<style lang="less" scoped>
#questionnaireRecord {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  > .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;

    > .el-tabs {
      width: 100%;
    }
    > .cc {
      width: 100%;
      height: 0;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      .item {
        width: 48%;
        display: flex;
        align-items: flex-start;
        margin-top: 15px;
        > .title {
          width: 180px;
          font-size: 15px;
          display: flex;
          justify-content: flex-end;
          padding-right: 8px;
          box-sizing: border-box;
          > i {
            margin-right: 5px;
            color: #ff4800;
          }
          > .va {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            > span {
              font-size: 13px;
              color: #6e80f5;
            }
          }
        }
        > .val {
          width: 0;
          flex: 1;
          box-sizing: border-box;
          /deep/.el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 100%;
          }
          .el-radio {
            width: 120px;
          }
          > .radio-input {
            width: 100%;
            height: auto;
            padding: 10px;
            box-sizing: border-box;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            display: flex;
            flex-direction: column;

            > .to {
              width: 100%;
              display: flex;
              height: auto;
              flex-wrap: wrap;
              /deep/.el-radio-group {
                width: 100%;
              }
              /deep/.el-radio,
              /deep/.el-checkbox {
                width: 48%;
                margin: 6px 0;
              }
            }
            > .bu {
              width: 100%;
              height: auto;
              margin-top: 10px;
              display: flex;
              > .ti {
                font-size: 14px;
                margin-right: 5px;
              }
              > .va {
                width: 0;
                flex: 1;
                /deep/.el-textarea {
                  width: 100%;
                }
              }
            }
          }
          .upload-file {
            width: 160px;
            height: 160px;
            background-color: #f1f1f1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8a8a8a;
            font-size: 45px;
            img {
              width: 160px;
              height: 160px;
            }
          }

          .text {
            width: 100px;
            height: 100px;
            font-size: 13px;
            cursor: pointer;
            position: relative;
            margin-right: 10px;
            margin-bottom: 10px;

            i {
              font-size: 25px;
            }

            .mb {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              background-color: #0303036e;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.5;
              color: #fff;

              > i {
                font-size: 15px;
                margin: 0 5px;
              }
            }

            &:hover .mb {
              opacity: 1;
            }
          }
        }
      }
      > .cl {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        > .line {
          font-size: 15px;
          display: flex;
          justify-content: flex-end;
          padding-right: 8px;
          padding-left: 20px;
          box-sizing: border-box;
          margin-bottom: 10px;
          > .val {
            margin-left: 8px;
          }
          > .title {
            font-weight: 600;
          }
        }
      }
      > .cco {
        width: 0;
        height: 100%;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        overflow-y: auto;
        > .item1 {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-content: flex-start;
          justify-content: flex-start;
          padding-left: 50px;
          box-sizing: border-box;
          > .titleName {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 15px;
          }
          /deep/.el-radio,
          /deep/.el-checkbox {
            width: 100% !important;
          }
          .title {
            width: 80px !important;
          }
        }
      }
    }

    > .buts {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      margin-top: 10px;
      > .but {
        width: auto;
        background-color: #3752ff;
        color: #fff;
        padding: 5px 60px;
        margin: 0 20px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>