import request from "@/utils/request";
let baseUrl = "/nursinghomes/goods/";

/**
 * @description: 获取商品详情内容
 * @return {*}
 */
export async function getGoodsOneById(data) {
  return await request({
    url: baseUrl + "getGoodsOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}


/**
 * @description: 商品详情的内容添加或修改(编辑器)
 * @return {*}
 */
export async function addOrUpdateGoodsOneDetail(data) {
  return await request({
    url: baseUrl + "addOrUpdateGoodsOneDetail",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
