import request from "@/utils/request";

/**
 * @description: 获取未处理异常数据数量
 * @return {*}
 */
export async function getExceptionElderlyRecordNum(data) {
  return await request({
    url: "/nursinghomes/sleepDeviceRecord/getExceptionElderlyRecordNum",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}



/**
 * @description: 修改密码
 * @return {*}
 */
export async function editAdminPassword(data) {
  return await request({
    url: "/nursinghomes/user/editAdminPassword",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
