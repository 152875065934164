//注册全局公用组件
import Vue from "vue";

import DiyDialog from './diy_dialog/diyDialog'
Vue.component('diy-dialog', DiyDialog)

import TopSearch from './top_search/topSearch.vue'
Vue.component('top-search', TopSearch)

import DiyTable from './diy_table/diyTable.vue'
Vue.component('diy-table', DiyTable)

import EditValue from './edit_value/editValue.vue'
Vue.component('edit-value', EditValue)