import request from "@/utils/request";
let baseUrl = "/nursinghomes/bed/";

/**
 * @description: 分页查询房间(包含设备)信息
 * @return {*}
 */
export async function getRoomListByPage(data) {
  return await request({
    url: baseUrl + "getRoomListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 查看老人的分配信息
 * @return {*}
 */
export async function getBedInfoByElderlyId(data) {
  return await request({
    url: baseUrl + "getBedInfoByElderlyId",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 新增或修改分配信息（床位和护工）
 * @return {*}
 */
export async function distributeBedInfo(data) {
  return await request({
    url: baseUrl + "distributeBedInfo",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 批量增加房间--床位
 * @return {*}
 */
export async function addOrUpdateBedList(data) {
  return await request({
    url: baseUrl + "addOrUpdateBedList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 增加或修改房间
 * @return {*}
 */
export async function updateRoomInfo(data) {
  return await request({
    url: baseUrl + "updateRoomInfo",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除床位
 * @return {*}
 */
export async function delBedOne(data) {
  return await request({
    url: baseUrl + "delBedOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条床位信息
 * @return {*}
 */
export async function getBedOneById(data) {
  return await request({
    url: baseUrl + "getBedOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 修改单条床位信息
 * @return {*}
 */
export async function addOrUpdateBedOne(data) {
  return await request({
    url: baseUrl + "addOrUpdateBedOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

