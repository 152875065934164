import request from "@/utils/request";
let baseUrl = "/nursinghomes/relationUserElderly/";

/**
 * @description: 查看老人联系人
 * @return {*}
 */
export async function getGuardianList(data) {
  return await request({
    url: baseUrl + "getGuardianList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 增加老人联系人
 * @return {*}
 */
export async function addContactPeople(data) {
  return await request({
    url: baseUrl + "addContactPeople",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 移除老人联系人
 * @return {*}
 */
export async function deleteContactPeople(data) {
  return await request({
    url: baseUrl + "deleteContactPeople",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}