/**
 * 界面基础数据
 * @returns
 */
export function baseData() {
  return {
    searchData: [
      {
          title: '地址名称',
          value: "",
          type: "input",
          key: "name"
      }
    ],
    searchButton: [
      {
        name: "新增",
        icon: "el-icon-circle-plus-outline",
        key: "addAddress",
      },
    ],
    menuData: [
      {
        key: "name",
        title: "地址信息",
        width: "",
      },
    ],
    tableButton: [
      { title: "修改", key: "edit" },
      { title: "删除", key: "del" },
    ],
  };
}

export function editBaseData() {
  return [
    {
      width: "45%",
      isNotNull: true,
      type: "input",
      title: "地址信息",
      key: "name",
      value: "",
    }
  ];
}
