<template>
  <div id="goods-index">
    <!-- 订单列表 -->
    <div class="search">
      <top-search
        :searchData="searchData"
        :searchButton="searchButton"
        @showSearchData="showSearchData"
        @handleClick="handleSearchClick"
      ></top-search>
    </div>
    <div class="content">
      <div class="table">
        <diy-table
          :tableData="tableData"
          :tableMenu="tableMenu"
          :model="model"
          :width="'360'"
          @showTableData="showTableData"
          @handleClick="handleTableClick"
          @TableClick="TableClick"
        ></diy-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as orderData from "./order.js";
import {
  getOrdersListByPage,
  delOrdersList,
  checkConsume,
} from "@/api/order/order.js";

export default {
  data() {
    return {
      searchData: orderData.baseData().searchData,
      searchButton: orderData.baseData().searchButton,
      tableData: [],
      tableMenu: orderData.baseData().menuData,
      model: "order",
      pageData: {
        currentPage: 1,
        total: 3000,
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        page: 1, // 当前页码
      },
      sortList: [],
    };
  },
  methods: {
    /**
     * 初始化界面数据
     */
    async initTable() {
      let requestData = {
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.currentPage,
      };
      requestData = this.$total.array2json(this.searchData, requestData);
      let resultData = await getOrdersListByPage(requestData);
      this.pageData.total = resultData.total;
      resultData.list.forEach((element) => {
        this.$set(element, "tableButton", orderData.baseData().tableButton);
      });
      this.tableData = resultData.list;
    },
    /**
     * 顶部搜索界面的按钮事件
     * @param key
     */
    handleSearchClick(key) {
      switch (key) {
        case "search":
          // 查询(默认所有界面均有)
          this.pageData.currentPage = 1;
          this.initTable();
          break;
        default:
          break;
      }
    },
    jugeEditPage(query, title, path) {
      let tag = this.$base.returnTag(title, query, path, path, "订单管理");
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push({
        path: tag.path,
        query: query,
      });
    },
    /**
     * 表格中按钮点击事件
     * @param val
     */
    async handleTableClick(val) {
      let row = val.row;
      let key = val.key;
      let requestData = {};
      let resultData = {};
      switch (key) {
        case "check":
          // 核销
          let requestData = { id: row.id };
          await checkConsume(requestData);
          this.$message({
            type: "success",
            message: "核销成功!",
          });
          await this.initTable();
          break;
        case "del":
          // 删除分类
          this.$confirm("此操作将永久删除所选的信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let requestData = { id: row.id };
              await delOrdersList(requestData);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              await this.initTable();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case "addOrderTask":
          console.log('row :>> ', row);
          // 核销
          this.jugeEditPage({type:"mall",id:row.id}, "创建商城任务", "/order/add");
          break;

        default:
          break;
      }
    },

    TableClick(val) {
      console.log(val);

      let row = val.row;
      let key = val.key;
      let requestData = {};
      let resultData = {};
      switch (key) {
        case "address":
          // 查看老人详情
          this.jugeEditPage({ id: row.id }, "查看内容详情", "/order/detail");
          break;
        case "orderList":
          // 删除分类
          this.jugeEditPage({ id: row.id }, "查看内容详情", "/order/detail");

          break;

        default:
          break;
      }
    },

    showTableData(val) {
      this.tableData = val;
    },
    showSearchData(val) {
      this.searchData = val;
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTable();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTable();
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  async mounted() {
    await this.initTable();
  },
};
</script>

<style lang="less" scoped>
#goods-index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;

  > .search {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    min-height: 80px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    > .table {
      width: 100%;
      flex: 1;
      height: 0;
    }
    > .page {
      width: 100%;
      height: 50px;
    }
  }
}
</style>
