import request from "@/utils/request";
let baseUrl = "/nursinghomes/address/";

/**
 * @description: 分页查询地址列表
 * @return {*}
 */
export async function getAddress(data) {
  return await request({
    url:  baseUrl +"getAddress",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 添加和修改信息
 * @return {*}
 */
export async function addOreditAddress(data) {
  return await request({
    url: baseUrl + "addOreditAddress",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除地址信息
 * @return {*}
 */
export async function delAddress(data) {
  return await request({
    url: baseUrl + "delAddress",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}


/**
 * @description: 获取单条地址信息
 * @return {*}
 */
export async function getAddressOneById(data) {
  return await request({
    url: baseUrl + "getAddressOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}