import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
import test from "../views/test.vue";

import Main from "../views/main.vue";
import Login from "../views/login.vue";

import Elderly from "../views/elderly/index.vue";
import Health from "../views/elderly/health.vue";
import AddElderly from "../views/elderly/add.vue";
import QuestionnaireRecordElderly from "../views/elderly/questionnaireRecord.vue";
import CareElderly from "../views/elderly/care.vue";

import Equipment from "../views/equipment/index.vue";

import Employ from "../views/empoly/index.vue";

import Task from "../views/task/index.vue";
import addTask from "../views/task/add.vue";
import TaskDetails from "../views/task/details.vue";

import Store from "../views/store/index.vue";

import Goods from "../views/shopping/goods/index.vue";
import addGoods from "../views/shopping/goods/add.vue";
import detailGoods from "../views/shopping/goods/detail.vue";

import GoodsConfig from "../views/shopping/goods_config/index.vue";
//yang
import Sort from "../views/shopping/sort/index.vue";
import addSort from "../views/shopping/sort/add.vue";

import Address from "../views/shopping/address/index.vue";
import Addaddress from "../views/shopping/address/add.vue";

import Order from "../views/shopping/order/index.vue";
import addOrder from "../views/shopping/order/add.vue";
import OrderDerail from "../views/shopping/order/detail.vue";

import ItemCollection from "../views/elderly/ItemCollection/index.vue";
import AddItemCollection from "../views/elderly/ItemCollection/add.vue";

import ItemRecord from "../views/elderly/ItemRecord/index.vue";
import Nurse from "../views/nurse/index.vue";
import RealData from "../views/realData/index.vue";
import AbnormalData from "../views/abnormalData/index.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/main",
    name: "main",
    component: Main,
    children: [
      {
        path: "/elderly",
        name: "elderly",
        component: Elderly,
      },
      {
        path: "/elderly/add",
        name: "addElderly",
        component: AddElderly,
      },
      {
        path: "/elderly/health",
        name: "Health",
        component: Health,
      },
      {
        path: "/elderly/questionnaireRecord",
        name: "questionnaireRecordElderly",
        component: QuestionnaireRecordElderly,
      },
      {
        path: "/elderly/care",
        name: "careElderly",
        component: CareElderly,
      },
      {
        path: "/equipment",
        name: "equipment",
        component: Equipment,
      },
      {
        path: "/task",
        name: "task",
        component: Task,
      },
      {
        path: "/task/add",
        name: "addTask",
        component: addTask,
      },
      {
        path: "/task/task-details",
        name: "taskDetails",
        component: TaskDetails,
      },
      {
        path: "/order",
        name: "Order", 
        component: Order,
      },
      {
        path: "/order/add",
        name: "addOrder", 
        component: addOrder,
      },
      {
        path: "/order/detail",
        name: "OrderDerail", 
        component: OrderDerail,
      },
      {
        path: "/customer",
        name: "test",
        component: test,
      },
      {
        path: "/goods",
        name: "Goods",
        component: Goods,
      },
      {
        path: "/goods/add",
        name: "addGoods",
        component: addGoods, 
      },
      {
        path: "/goods/detail",
        name: "detailGoods",
        component: detailGoods, 
      },
      {
        path: "/goods-config",
        name: "GoodsConfig",
        component: GoodsConfig,
      },
      {
        path: "/employ",
        name: "employ",
        component: Employ,
      },
      {
        path: "/questionnaire",
        name: "test",
        component: test,
      },
      {
        path: "/store",
        name: "store",
        component: Store,
      },
      {
        path: "/notice",
        name: "test",
        component: test,
      },
      //yang
      {
        path: "/sort",
        name: "Sort",
        component: Sort,
      },
      {
        path: "/sort/add",
        name: "addSort",
        component: addSort,
      },
      {
        path: "/address",
        name: "Address",
        component: Address,
      },
      {
        path: "/address/add",
        name: "Addaddress",
        component: Addaddress,
      },
      {
        path: "/ItemCollection",
        name: "ItemCollection",
        component: ItemCollection,
      },
      {
        path: "/ItemCollection/add",
        name: "AddItemCollection",
        component: AddItemCollection,
      },
      {
        path: "/ItemRecord",
        name: "ItemRecord",
        component: ItemRecord,
      },
      {
        path: "/nurse",
        name: "Nurse",
        component: Nurse,
      },
      {
        path: "/realData",
        name: "RealData",
        component: RealData,
      },
      {
        path: "/abnormalData",
        name: "AbnormalData",
        component: AbnormalData,
      },
    ],
  },
  {
    path: "/test",
    name: "test",
    component: test,
  },
];

const router = new VueRouter({
  routes,
});

/**
 * 导航守卫
 */
router.beforeEach(async (to, from, next) => {
  // if (to.path != '/' && to.path.indexOf('test') < 0) {
  //     try {
  //         let route_now = to.path
  //         let data = await getAuthorityPath()
  //         let flag = false
  //         console.log("当前路由", route_now);
  //         data.forEach(element => {
  //             if (route_now == element) {
  //                 flag = true
  //             }
  //         });
  //         if (flag) {
  //             // 允许跳转
  //             next()
  //         } else {
  //             Message({
  //                 message: "您没有权限,请联系管理员!",
  //                 type: 'error'
  //             })
  //         }
  //     } catch (error) {

  //     }
  // } else {
  next();
  // }
});

//重复路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
