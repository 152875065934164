import request from "@/utils/request";
let baseUrl = "/nursinghomes/dictionary/";

/**
 * @description: 获取分类列表
 * @return {*}
 */
export async function getSort(data) {
  return await request({
    url: baseUrl + "getSort",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除分类列表
 * @return {*}
 */
export async function delSort(data) {
  return await request({
    url: baseUrl + "delSort",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 添加或修改分类
 * @return {*}
 */
export async function addOrEditSort(data) {
  return await request({
    url: baseUrl + "addOrEditSort",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条分类信息
 * @return {*}
 */
export async function getSortOneById(data) {
  return await request({
    url: baseUrl + "getSortOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}