<template>
  <div id="cycle">
    <div class="outer">
      <div class="con">
        <div class="lf">护理周期配置</div>
        <div class="re ">
          <div class="empty" v-if="data && data.length == 0">
            <el-empty description="暂无护理内容">
              <el-button type="primary" @click="addCare"
                >添加护理周期</el-button
              >
            </el-empty>
          </div>
          <div class="cc" v-else>
            <div class="item" v-for="(item, index) in data">
              <div class="l">
                <div class="h">
                  <div class="hi">
                    <div class="title">护理周期名称</div>
                    <div class="val">
                      <el-input
                        v-model="item.name"
                        disabled
                        placeholder="请输入护理周期名称"
                      ></el-input>
                    </div>
                  </div>
                  <div class="hi">
                    <div class="title">周期间隔(h)</div>
                    <div class="val">
                      <el-input
                        v-model="item.cycleTime"
                        disabled
                        placeholder="请输入护理周期间隔（数字）"
                      ></el-input>
                    </div>
                  </div>
                </div>
                <div class="h">
                  <div class="hi">
                    <div class="title">提醒时间</div>
                    <div class="val">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="item.remark"
                        disabled
                        placeholder="请输入提醒时间（使用逗号分隔）"
                      ></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="r">
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="updateC(item)"
                  ><i class="el-icon-edit" style="margin-right: 5px"></i
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="removeItem(item)"
                  ><i class="el-icon-minus" style="margin-right: 5px"></i
                  >移除</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="but">
        <el-button type="primary" @click="addCare">添加护理周期</el-button>
      </div>
    </div>

    <diy-dialog
      v-if="dialogData.flag"
      :dialogData="dialogData.data"
      :title="dialogData.title"
      :width="dialogData.width"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
    ></diy-dialog>
  </div>
</template>

<script>
import { getCycleList, addOrEditCycle, delCycle } from "@/api/nurse/nurse.js";
export default {
  data() {
    return {
      elderlyId: "",
      data: [],
      cycleList: [], // 护理周期

      dialogData: {
        tempData: {},
        flag: false,
        data: [],
        title: "添加护理周期",
        width: "40%",
        buttonList: [],
      },
    };
  },
  methods: {
    async initData() {
      let requestData = {};
      let resultData = await getCycleList(requestData);
      this.data = resultData.list;
    },
    addCare() {
      this.dialogData.title = "添加护理周期";
      this.openDialog({}, "makesureAdd");
      this.dialogData.flag = true;
    },
    updateC(item) {
      this.dialogData.title = "编辑护理周期";
      this.openDialog({}, "makesureUpdate");
      this.dialogData.tempData = item;
      this.dialogData.data = this.$total.json2array(this.dialogData.data, item);
      this.dialogData.flag = true;
    },
    openDialog(data, key) {
      this.dialogData.data = [
        {
          title: "护理名称",
          type: "input",
          value: "",
          key: "name",
          isNotNull: true,
        },
        {
          title: "护理间隔(h)",
          type: "input",
          value: "",
          key: "cycleTime",
          isNotNull: true,
        },
        {
          title: "提醒时间",
          placeholder: "请输入提醒时间（使用逗号分隔）",
          type: "textarea",
          value: "",
          key: "remark",
          isNotNull: true,
        },
      ];
      if (data.id) {
        this.dialogData.data = this.$total.json2array(
          this.dialogData.data,
          data
        );
      }
      this.dialogData.buttonList = [
        { active: key, text: "确定" },
        { active: "cancel", text: "取消" },
      ];
    },
    async removeItem(data) {
      let requestData = {
        idList: [data.id],
      };
      await delCycle(requestData);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      await this.initData();
    },
    async save(requestData) {
      requestData = this.$total.array2json(this.dialogData.data, requestData);
      requestData.remark = requestData.remark + "";
      let mes = this.$total.judegArrayNull(this.dialogData.data, this.data);
      if (mes != "ok") {
        this.$message({
          message: mes + "不可为空!",
          type: "error",
        });
        return;
      }
      await addOrEditCycle(requestData);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      await this.initData();
      this.colseDialog();
    },
    async dialogOper(key) {
      let requestData = {};
      switch (key) {
        case "makesureAdd":
          // 确定添加老人日常护理
          requestData = {
            id: "",
            elderlyId: this.elderlyId,
          };
          await this.save(requestData);
          break;
        case "makesureUpdate":
          // 确定添加老人日常护理
          requestData = {
            id: this.dialogData.tempData.id,
            elderlyId: this.elderlyId,
          };
          await this.save(requestData);
          break;
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  async mounted() {
    this.elderlyId = this.$route.query.id
      ? Number.parseInt(this.$route.query.id)
      : "";
    // await this.getBaseData();
    await this.initData();
  },
};
</script>

<style lang="less" scoped>
#cycle {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  .outer {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;
    > .con {
      width: 100%;
      max-height: 80%;
      overflow-y: auto;
      display: flex;

      > .lf {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        font-size: 16px;
        font-weight: 600;
        padding-right: 20px;
        box-sizing: border-box;
      }
      > .re {
        // width: 0;
        flex: 1;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        > .empty {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }
        > .cc {
          width: 100%;
          display: flex;
          height: 100%;
          flex-direction: column;
          > .item {
            width: 60%;
            height: 140px;
            background-color: #f6f6f6;
            display: flex;
            border-radius: 5px;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
            /deep/.el-select {
              width: 100%;
            }
            > .l {
              width: 0;
              flex: 1;
              height: 100%;
              display: flex;
              flex-direction: column;
              > .h {
                flex: 1;
                display: flex;
              }
            }
            > .r {
              width: auto;
              height: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              margin-left: 20px;
            }
            .hi {
              flex: 1;
              display: flex;
              > .title {
                width: 100px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-right: 8px;
                font-size: 14px;
              }
              > .val {
                width: 0;
                flex: 1;
              }
            }
          }
        }
      }
    }
    > .but {
      display: flex;
      padding-left: 10%;
      margin-top: 40px;
    }
  }
}
</style>
