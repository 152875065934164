/**
 * 界面基础数据
 * @returns
 */
export function baseData() {
  return {
    searchData: [
      {
        title: '订单时间',
        value: ["",""],
        type: "time",
        key: "time"
    }
    ],
    searchButton: [
      // {
      //   name: "创建商城任务",
      //   icon: "el-icon-circle-plus-outline",
      //   key: "addOrderTask",
      // },
    ],
    menuData: [
      {
        key: "time",
        title: "下单时间",
        width: "",
      },
      {
        key: "status",
        title: "下单状态",
        width: "",
        isChangeColor: true,
      },
      {
        key: "totalPrice",
        title: "总金额",
        width: "",
      },
      {
        key: "phone",
        title: "手机号",
        width: "",
      },
      {
        key: "address",
        title: "下单地址",
        width: "",
        Detail:true,
        showDetail: "showDetail"

      },{
        key: "orderList",
        title: "下单商品",
        width: "",
        Detail:true,
        showDetail: "showDetail"

      },
      
    ],
    tableButton: [
      { title: "核销", key: "check" },
      { title: "删除", key: "del" },
      { title: "商城任务", key: "addOrderTask" },
    ],
  };
}

/**
 * 创建系统任务
 * @param {*}
 * @returns
 */
export function editBaseData() {
  return [
    {
      width: "50%",
      isNotNull: true,
      type: "input",
      title: "任务名称",
      key: "titleName",
      value: "",
    },
    // {
    //   width: "50%",
    //   isNotNull: true,
    //   type: "select",
    //   title: "任务类型",
    //   key: "type",
    //   value: "",
    //   options: [
    //     {
    //       id: "system",
    //       value: "系统任务",
    //     },
    //     {
    //       id: "mall",
    //       value: "商城任务",
    //     },
    //   ],
    // },
    
    {
      width: "50%",
      isNotNull: true,
      type: "select",
      title: "状态",
      key: "status",
      value: "",
      options: [
        {
          id: "w",
          value: "待执行",
        },
        {
          id: "y",
          value: "已完成",
        },
        {
          id: "n",
          value: "失败",
        },
      ],
    },
    {
      width: "50%",
      isNotNull: true,
      type: "select",
      title: "员工",
      key: "employeeId",
      value: "",
      options: [],
    },

    {
      width: "50%",
      isNotNull: true,
      type: "date",
      title: "执行时间",
      key: "needTime",
      value: "",
    },
    {
        width: "50%",
        isNotNull: true,
        type: "task-content",
        title: "子任务",
        key: "content",
        value: [],
        ChildrenorderData:[],
        ChildrenTaskOptions: [
            {
              id: "w",
              value: "待执行",
            },
            {
              id: "y",
              value: "已完成",
            },
            {
              id: "n",
              value: "失败",
            },
          ], //子任务选项
      },
  ];
}
