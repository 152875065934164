/**
 * 界面基础数据
 * @returns
 */
export function baseData() {
  return {
    searchData: [
      {
        title: "商品名称",
        value: "",
        type: "input",
        key: "name",
      },
      {
        title: "分类",
        value: "",
        type: "select",
        key: "sortId",
        options: [],
      },
    ],
    searchButton: [
      {
        name: "新增",
        icon: "el-icon-circle-plus-outline",
        key: "addElderly",
      },
    ],
    menuData: [
      {
        key: "category",
        title: "分类",
        width: "",
      },
      {
        key: "name",
        title: "商品名称",
        width: "",
      },
      {
        key: "price",
        title: "商品价格(元)",
        width: "",
      },
      {
        key: "imgurl",
        title: "商品图片",
        width: "",
        showImage: true,
      },
      {
        key: "goodsDetail",
        title: "商品详情",
        width: "",
        Detail:true,
        showDetail: "showDetail"
      },
    ],
    tableButton: [
      { title: "修改", key: "edit" },
      { title: "删除", key: "del" },
    ],
  };
}

export function editBaseData() {
  return [
    {
      width: "70%",
      isNotNull: true,
      type: "img",
      title: "商品图片",
      key: "imgurl",
      value: "",
      uploadData: {
        action: process.env.VUE_APP_BASE_API + "nursinghomes/file/uploadFile",
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "老人头像",
          key: "imgurl",
        },
      },
    },
    {
      width: "45%",
      isNotNull: true,
      type: "select",
      title: "商品分类",
      key: "sortId",
      value: "",
      options: [],
    },
    {
      width: "45%",
      isNotNull: true,
      type: "input",
      title: "商品名称",
      key: "name",
      value: "",
    },
    {
      width: "45%",
      isNotNull: true,
      type: "input",
      title: "商品价格(元)",
      key: "price",
      value: "",
    },
    
  ];
}
