import request from "@/utils/request";
let baseUrl = "/nursinghomes/dictionary/";

/**
 * @description: 获取老人日常护理
 * @return {*}
 */
export async function getCycleList(data) {
    return await request({
        url: baseUrl + "getCycleList",
        headers: {
            isToken: true,
        },
        method: "post",
        data: data,
        timeout: 20000,
    });
}

/**
 * @description: 增加或修改单条老人日常护理
 * @return {*}
 */
export async function addOrEditCycle(data) {
    return await request({
        url: baseUrl + "addOrEditCycle",
        headers: {
            isToken: true,
        },
        method: "post",
        data: data,
        timeout: 20000,
    });
}

/**
 * @description: 删除老人日常护理
 * @return {*}
 */
export async function delCycle(data) {
    return await request({
        url: baseUrl + "delCycle",
        headers: {
            isToken: true,
        },
        method: "post",
        data: data,
        timeout: 20000,
    });
}