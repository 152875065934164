import request from "@/utils/request";
let baseUrl = "/nursinghomes/sleepDeviceRecord/";

/**
 * @description: 获取异常数据列表
 * @return {*}
 */
export async function getExceptionElderlyRecord(data) {
  return await request({
    url: baseUrl + "getExceptionElderlyRecord",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 处理异常数据
 * @return {*}
 */
export async function checkExceptionElderlyRecord(data) {
  return await request({
    url: baseUrl + "checkExceptionElderlyRecord",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
