<template>
  <div id="add-goods">
    <!-- 添加系统任务 -->
    <div class="con">
      <div class="item" v-for="item in data" :style="{ width: item.width }">
        <div class="title">
          <i class="el-icon-star-on" v-if="item.isNotNull"></i>
          <div class="va">
            {{ item.title }}
            <span v-if="item.subTitle">{{ item.subTitle }}</span>
          </div>
        </div>
        <div class="val">
          <el-button
            v-if="item.type == 'task-content'"
            type="primary"
            @click="addNewTask(item)"
            >添加子任务</el-button
          >
          <div
            class="cc"
            v-if="
              item.value && item.value.length > 0 && item.type == 'task-content'
            "
          >
            <div class="item" v-for="(it, index) in item.value">
              <div class="l">
                <div class="h">
                  <div class="hi">
                    <div class="title">
                      <span style="color: 000; font-weight: 900"
                        >({{ index + 1 }})</span
                      >子任务名称
                    </div>
                    <div class="val">
                      <el-input
                        v-model="it.name"
                        placeholder="请输入子任务名称"
                        :disabled="!it.isEditing"
                      ></el-input>
                    </div>
                  </div>
                  <div class="hi">
                    <div class="title">子任务状态</div>
                    <div class="val">
                      <el-select
                        v-model="it.status"
                        placeholder="子任务状态"
                        :disabled="!it.isEditing"
                      >
                        <el-option
                          v-for="op in item.ChildrenTaskOptions"
                          :key="op.id"
                          :label="op.value"
                          :value="op.id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="h">
                  <div class="hi">
                    <div class="title">备注</div>
                    <div class="val">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="it.remark"
                        placeholder="请输入备注"
                        :disabled="!it.isEditing"
                      ></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="r">
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="edit(it, index)"
                  ><i class="el-icon-edit" style="margin-right: 5px"></i
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="remove(it, index)"
                  ><i class="el-icon-minus" style="margin-right: 0px"></i
                  >移除</el-button
                >
                <!-- <el-button
                  type="primary"
                  plain
                  size="mini"
                  @click="makeSureTask(it)"
                  ><i class="el-icon-check" style="margin-right: 5px"></i
                  >确定</el-button
                >
                <el-button type="simple" plain size="mini" @click="cancel(it)"
                  ><i class="el-icon-minus" style="margin-right: 0px"></i
                  >取消</el-button
                > -->
              </div>
            </div>
          </div>
          <el-input
            v-if="item.type == 'input'"
            v-model="item.value"
            :placeholder="'请输入' + item.title"
            clearable
          ></el-input>
          <div v-if="item.type == 'select'" class="selct">
            <el-select
              v-model="item.value"
              filterable
              :placeholder="'请选择' + item.title"
              :disabled="item.flag"
            >
              <el-option
                v-for="it in item.options"
                :key="it.id"
                :label="it.value"
                :value="it.id"
              >
              </el-option>
            </el-select>
          </div>

          <el-date-picker
            v-if="item.type == 'date'"
            v-model="item.value"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="buts">
        <div class="but" @click="makeSure">确定</div>
        <div class="but" @click="cancel">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as orderData from "./task.js";
import {
  getTaskOneById,
  addOrUpdateTaskOne,
  getAllEmployeeList,
} from "@/api/task/task.js";

export default {
  data() {
    return {
      data: orderData.editBaseData(),
      id: "",
      EmployeeList: [], //护工列表
      // dialogData: {
      //   tempData: {},
      //   flag: false,
      //   data: [],
      //   title: "添加护理周期",
      //   width: "40%",
      //   buttonList: [],
      // },
      ChildrenTask: false, //子任务
      ChildrenorderData: [
        // {
        //   id: "",
        //   name: "",
        //   remark: "",
        //   status: "",
        //   isEditing: false,
        // },
      ], //子任务数据
      ChildrenTaskOptions: [
        {
          id: "w",
          value: "待执行",
        },
        {
          id: "y",
          value: "已完成",
        },
        {
          id: "n",
          value: "失败",
        },
      ], //子任务选项
      type: "",
      orderId: "",
    };
  },
  methods: {
    async initData() {
      let requestData = {
        id: this.id,
      };
      let resultData = await getTaskOneById(requestData);
      if (!resultData.id) {
        return;
      }
      this.data = this.$total.json2array(this.data, resultData);
    },
    async makeSure() {
      let requestData = {
        id: this.id,
      };
      let mes = this.$total.judegArrayNull(this.data);
      if (mes != "ok") {
        this.$message({
          message: mes + "不可为空!",
          type: "error",
        });
        return;
      }
      let mes1 = "";
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].key == "content") {
          if (this.data[i].value.name == "") {
            mes1 = "错误";
            return;
          }
          if (this.data[i].value.status == "") {
            mes1 = "错误";
            return;
          } else {
            mes1 = "ok";
          }
        }
      }
      if (mes1 != "ok") {
        this.$message({
          message: "子任务填写不完整!",
          type: "error",
        });
        return;
      }
      requestData.type = "system";
      requestData.id = this.id;
      requestData = this.$total.array2json(this.data, requestData);
      requestData.content.forEach((item) => {
        item.isEditing = false;
      });
      await addOrUpdateTaskOne(requestData);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      this.cancel();
    },
    //

    //添加子任务
    addNewTask() {
      const newTask = {
        id: Date.now(), // 使用时间戳作为唯一ID
        name: "",
        remark: "",
        status: "",
        isEditing: true, // 新增任务时进入编辑模式
      };
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].key == "content") {
          // this.data[i].ChildrenorderData.push(newTask);
          if (!this.data[i].value) {
            this.data[i].value = [];
          }
          this.data[i].value.push(newTask);
          return;
        }
      }
    },
    edit(it, index) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].key == "content") {
          this.$set(this.data[i].value[index], "isEditing", true);
          console.log("this.data[i].value :>> ", this.data[i].value);
        }
      }
    },
    makeSureTask(item) {
      item.isEditing = false; // 退出编辑模式
      // 这里可以添加保存数据的逻辑
    },
    cancel(item) {
      item.isEditing = false; // 退出编辑模式，不保存修改
    },
    remove(it, index) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].key == "content") {
          this.data[i].value = this.data[i].value.filter((t) => t.id !== it.id);
          return;
        }
      }
    },
    cancel() {
      this.$store.dispatch("tagsView/delView", this.$router.currentRoute);
      this.$router.go(-1);
    },
    beforeAvatarUpload(val) {},
    handleAvatarSuccess(val) {
      console.log(val);
      if (val.code == "200") {
        let resultData = val.result;
        this.data.forEach((element) => {
          if (element.key == resultData.key) {
            element.value = resultData.path;
          }
        });
      }
    },
    showEditValueData(val) {
      this.data = val;
    },
  },
  async mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : "";
    this.EmployeeList = [];
    let EmployeeList = await getAllEmployeeList({});
    this.$total.fillArrayOptions(this.data, "employeeId", EmployeeList);

    if (this.id != "") {
      await this.initData();
    }
  },
};
</script>

<style lang="less" scoped>
#add-goods {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;

  > .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    > .buts {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      margin-top: 10px;
      > .but {
        width: auto;
        background-color: #3752ff;
        color: #fff;
        padding: 5px 60px;
        margin: 0 20px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    > .item {
      display: flex;
      align-items: flex-start;
      margin-top: 15px;
      // height: 50px;

      > .title {
        width: 120px;
        font-size: 15px;
        display: flex;
        justify-content: flex-end;
        padding-right: 8px;
        box-sizing: border-box;
        > i {
          margin-right: 5px;
          color: #ff4800;
        }
        > .va {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          > span {
            font-size: 13px;
            color: #6e80f5;
          }
        }
      }
      > .val {
        width: 0;
        flex: 1;
        box-sizing: border-box;
        min-height: 50px;
        /deep/.el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
        .el-radio {
          width: 120px;
        }
        > .show-img {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          > img {
            margin: 0 10px 10px 0;
          }
        }
        > .task-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          > .item {
            width: 70%;
            background-color: #ebebebb4;
            display: flex;
            flex-wrap: wrap;
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 10px;
            box-sizing: border-box;
            > .it {
              width: 50%;
              display: flex;
              font-size: 14px;
              margin-bottom: 8px;
              > .ti {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-weight: 600;
                margin-right: 7px;
              }
            }
          }
        }
        > .radio-input {
          width: 100%;
          height: auto;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          display: flex;
          flex-direction: column;

          > .to {
            width: 100%;
            display: flex;
            height: auto;
            flex-wrap: wrap;
            /deep/.el-radio-group {
              width: 100%;
            }
            /deep/.el-radio,
            /deep/.el-checkbox {
              width: 48%;
              margin: 6px 0;
            }
          }
          > .bu {
            width: 100%;
            height: auto;
            margin-top: 10px;
            display: flex;
            > .ti {
              font-size: 14px;
              margin-right: 5px;
            }
            > .va {
              width: 0;
              flex: 1;
              /deep/.el-textarea {
                width: 100%;
              }
            }
          }
        }
        .upload-file {
          width: 160px;
          height: 160px;
          background-color: #f1f1f1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #8a8a8a;
          font-size: 45px;
          img {
            width: 160px;
            height: 160px;
          }
        }

        .selct {
          width: 100%;
          > .el-select {
            width: 100%;
          }
        }

        .text {
          width: 100px;
          height: 100px;
          font-size: 13px;
          cursor: pointer;
          position: relative;
          margin-right: 10px;
          margin-bottom: 10px;

          i {
            font-size: 25px;
          }

          .mb {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            background-color: #0303036e;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.5;
            color: #fff;

            > i {
              font-size: 15px;
              margin: 0 5px;
            }
          }

          &:hover .mb {
            opacity: 1;
          }
        }

        > .cc {
          width: 100%;
          display: flex;
          flex-direction: column;
          max-height: 315px;
          height: auto;
          margin-top: 10px;
          overflow-y: auto;

          > .item {
            width: 100%;
            height: 140px;
            background-color: #f6f6f6;
            display: flex;
            border-radius: 5px;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
            /deep/.el-select {
              width: 100%;
            }
            > .l {
              width: 0;
              flex: 1;
              height: 100%;
              display: flex;
              flex-direction: column;
              > .h {
                flex: 1;
                display: flex;
              }
            }
            > .r {
              width: auto;
              width: 200px;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: flex-end;
              margin-left: 10px;
            }
            .hi {
              flex: 1;
              display: flex;
              > .title {
                width: 100px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-right: 8px;
                font-size: 14px;
              }
              > .val {
                width: 0;
                flex: 1;
              }
            }
          }
          > .but {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
