import request from "@/utils/request";
let baseUrl = "/nursinghomes/everydayCare/";

/**
 * @description: 获取老人日常护理
 * @return {*}
 */
export async function getEverydayCareListByPage(data) {
    return await request({
        url: baseUrl + "getEverydayCareListByPage",
        headers: {
            isToken: true,
        },
        method: "post",
        data: data,
        timeout: 20000,
    });
}

/**
 * @description: 增加或修改单条老人日常护理
 * @return {*}
 */
export async function addOrUpdateEverydayCareOne(data) {
    return await request({
        url: baseUrl + "addOrUpdateEverydayCareOne",
        headers: {
            isToken: true,
        },
        method: "post",
        data: data,
        timeout: 20000,
    });
}

/**
 * @description: 删除老人日常护理
 * @return {*}
 */
export async function delEverydayCareList(data) {
    return await request({
        url: baseUrl + "delEverydayCareList",
        headers: {
            isToken: true,
        },
        method: "post",
        data: data,
        timeout: 20000,
    });
}