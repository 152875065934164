<template>
  <div id="employ">
    <!-- 员工设置 -->
    <div class="search">
      <top-search
        :searchData="searchData"
        :searchButton="searchButton"
        @showSearchData=""
        showSearchData
        @handleClick="handleSearchClick"
      ></top-search>
    </div>
    <div class="content">
      <div class="table">
        <diy-table
          :tableData="tableData"
          :tableMenu="tableMenu"
          :model="model"
          :width="'180'"
          @showTableData="showTableData"
          @handleClick="handleTableClick"
        ></diy-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>

    <diy-dialog
      v-if="dialogData.flag"
      :dialogData="dialogData.data"
      :title="dialogData.title"
      :width="dialogData.width"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
    ></diy-dialog>
  </div>
</template>

<script>
import * as EmployData from "./employ.js";
import {
  getUserListByPage,
  addOrUpdateUserOne,
  delUserList,
} from "@/api/employ/employ.js";
export default {
  data() {
    return {
      searchData: EmployData.baseData().searchData,
      searchButton: EmployData.baseData().searchButton,
      tableData: [],
      tableMenu: EmployData.baseData().menuData,
      model: "employ",
      pageData: {
        currentPage: 1,
        total: 3000,
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        page: 1, // 当前页码
      },

      dialogData: {
        tempData: {},
        flag: false,
        data: [],
        title: "添加护理周期",
        width: "40%",
        buttonList: [],
      },
    };
  },
  methods: {
    /**
     * 初始化界面数据
     */
    async initTable() {
      let requestData = {
        type: "employee",
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.currentPage,
      };
      requestData = this.$total.array2json(this.searchData, requestData);
      let resultData = await getUserListByPage(requestData);
      this.pageData.total = resultData.total;

      resultData.list.forEach((element) => {
        this.$set(element, "tableButton", EmployData.baseData().tableButton);
      });
      this.tableData = resultData.list;
    },
    /**
     * 顶部搜索界面的按钮事件
     * @param key
     */
    handleSearchClick(key) {
      switch (key) {
        case "search":
          // 查询(默认所有界面均有)
          this.pageData.currentPage = 1;
          this.initTable();
          break;
        case "addEmploy":
          // 新增员工信息
          this.openDialog({}, "makeSureAdd");
          this.dialogData.tempData = {};
          this.dialogData.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     * 表格中按钮点击事件
     * @param val
     */
    handleTableClick(val) {
      let row = val.row;
      let key = val.key;
      let requestData = {};
      switch (key) {
        case "edit":
          // 编辑
          this.openDialog(row, "makeSureEdit");
          this.dialogData.tempData = row;
          this.dialogData.flag = true;
          break;
        case "del":
          // 删除
          this.$confirm("此操作将永久删除该员工信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              requestData = {
                idList: [row.id],
              };
              await delUserList(requestData);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              await this.initTable();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case "resetPsw":
          // 密码重置
          this.$confirm(
            "此操作将永久重置该员工系统账号,重置后的密码为系统默认密码:123456, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(async () => {
              requestData = {
                id: row.id,
                password: "123456",
              };
              await addOrUpdateUserOne(requestData);
              this.$message({
                type: "success",
                message: "密码重置成功!",
              });
              await this.initTable();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          break;

        default:
          break;
      }
    },
    openDialog(data, key) {
      this.dialogData.title =
        key == "makeSureAdd" ? "添加员工" : "编辑员工信息";
      this.dialogData.data = [
        {
          title: "姓名",
          key: "userName",
          value: "",
          type: "input",
          isNotNull: true,
        },
        {
          title: "手机号",
          key: "phoneNumber",
          value: "",
          type: "input",
          isNotNull: true,
        },
        // {
        //   title: "系统账号",
        //   key: "loginName",
        //   value: "",
        //   type: "input",
        //   isNotNull: true,
        // },
        {
          title: "在职状态",
          key: "isEmployed",
          value: "",
          type: "radio",
          isNotNull: true,
          options: [
            { label: "在职", value: "在职" },
            { label: "离职", value: "离职" },
          ],
        },
        {
          title: "性别",
          key: "sex",
          value: "",
          type: "radio",
          options: [
            { label: "男", value: "男" },
            { label: "女", value: "女" },
          ],
        },
        { title: "年龄", key: "age", value: "", type: "input" },
        // { title: "家庭住址", key: "address", value: "", type: "textarea" },
      ];
      if (data.id) {
        this.dialogData.data = this.$total.json2array(
          this.dialogData.data,
          data
        );
      }
      this.dialogData.buttonList = [
        { active: key, text: "确定" },
        { active: "cancel", text: "取消" },
      ];
    },
    async dialogOper(key) {
      let requestData = {};
      switch (key) {
        case "makeSureAdd":
          // 确认添加员工信息
          requestData = { id: "", password: "123456", type: "employee" };

          await this.save(requestData);
          break;
        case "makeSureEdit":
          // 确认编辑员工信息
          requestData = { id: this.dialogData.tempData.id };
          await this.save(requestData);
          break;
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    async save(requestData) {
      requestData = this.$total.array2json(this.dialogData.data, requestData);
      let mes = this.$total.judegArrayNull(this.dialogData.data, this.data);
      let flag = this.$total.mobilePhoneNumberCheck(requestData.phoneNumber);
      if (mes != "ok") {
        this.$message({
          message: mes + "不可为空!",
          type: "error",
        });
        return;
      }
      if (!flag) {
        this.$message({
          message: "手机号格式不正确!",
          type: "error",
        });
        return;
      }
      await addOrUpdateUserOne(requestData);
      this.dialogData.flag = false;
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      await this.initTable();
    },
    showTableData(val) {
      this.tableData = val;
    },
    showSearchData(val) {
      this.searchData = val;
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTable();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTable();
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  async mounted() {
    await this.initTable();
  },
};
</script>

<style lang="less" scoped>
#employ {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;

  > .search {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    min-height: 80px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    > .table {
      width: 100%;
      flex: 1;
      height: 0;
    }
    > .page {
      width: 100%;
      height: 50px;
    }
  }
}
</style>
