import request from "@/utils/request";
let baseUrl = "/nursinghomes/storeItem/";

/**
 * @description: 分页查询物品分类列表
 * @return {*}
 */
export async function getStoreItemListByPage(data) {
  return await request({
    url: baseUrl + "getStoreItemListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除分类列表
 * @return {*}
 */
export async function delStoreItemList(data) {
  return await request({
    url: baseUrl + "delStoreItemList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 添加或修改分类
 * @return {*}
 */
export async function addOrUpdateStoreItemOne(data) {
  return await request({
    url: baseUrl + "addOrUpdateStoreItemOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条分类信息
 * @return {*}
 */
export async function getStoreItemOneById(data) {
  return await request({
    url: baseUrl + "getStoreItemOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}