import request from "@/utils/request";
let baseUrl = "/nursinghomes/dictionary/";

/**
 * @description: 获取字典列表
 * key cycle--护理周期
 * @return {*} 
 */
export async function getDictionaryList(data) {
  return await request({
    url: baseUrl + "getDictionaryList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 添加/编辑字典列表
 * @return {*} 
 */
export async function saveOrUpdateDictionary(data) {
  return await request({
    url: baseUrl + "saveOrUpdateDictionary",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除字典列表
 * @return {*} 
 */
export async function delDictionary(data) {
  return await request({
    url: baseUrl + "delDictionary",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}