<template>
  <div id="elderly-contacts">
    <!-- 老人联系人 -->
    <el-empty v-if="data.length == 0" description="暂无联系人">
      <el-button type="primary" @click="addContact">添加联系人</el-button>
    </el-empty>
    <div class="con customScrollBar" v-else>
      <div class="item" v-for="item in data">
        <div class="val1">{{ item.guardianName }}</div>
        <div class="val">{{ item.guardianPhoneNumber }}</div>
        <div class="but">
          <el-button type="danger" plain @click="handelButClick(item)" size="mini"
            ><i class="el-icon-delete"></i>移除</el-button
          >
        </div>
      </div>
      <el-button type="primary" @click="addContact">添加联系人</el-button>
    </div>

    <diy-dialog
      v-if="dialogData.flag"
      :dialogData="dialogData.data"
      :title="dialogData.title"
      :width="dialogData.width"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
    ></diy-dialog>
  </div>
</template>

<script>
import {
  getGuardianList,
  addContactPeople,
  deleteContactPeople,
} from "@/api/elderly/relationUserElderly.js";
export default {
  props: {
    contactsData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    id: {
      // 老人ID
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: this.contactsData,

      dialogData: {
        tempData: {},
        flag: false,
        data: [],
        title: "添加护理周期",
        width: "40%",
        buttonList: [],
      },
    };
  },
  watch: {
    contactsData() {
      this.data = this.contactsData;
    },
    data(newValue) {
      this.$emit("showContactsData", this.data);
    },
  },
  methods: {
    async initTable() {
      this.data = await getGuardianList({ elderlyId: this.id });
    },
    addContact() {
      this.dialogData.title = "添加联系人";
      this.dialogData.width = "30%";
      this.dialogData.data = [
        {
          title: "联系人姓名",
          type: "input",
          value: "",
          key: "guardianName",
          isNotNull: true,
        },
        {
          title: "联系方式",
          type: "input",
          value: "",
          key: "guardianPhoneNumber",
          isNotNull: true,
        },
      ];
      this.dialogData.buttonList = [
        { active: "makesure", text: "确定" },
        { active: "cancel", text: "取消" },
      ];
      this.dialogData.flag = true;
    },
    async dialogOper(key) {
      let requestData = {};
      switch (key) {
        case "makesure":
          // 确定添加联系人
          requestData = {
            elderlyId: this.id,
          };
          requestData = this.$total.array2json(
            this.dialogData.data,
            requestData
          );
         let mes = this.$total.judegArrayNull(this.dialogData.data, this.data);
          if (mes != "ok") {
            this.$message({
              message: mes + "不可为空!",
              type: "error",
            });
            return;
          }
          let flag = this.$total.mobilePhoneNumberCheck(requestData.guardianPhoneNumber);
          if (!flag) {
            this.$message({
              message: "联系方式格式不对!",
              type: "error",
            });
            return;
          }
          await addContactPeople(requestData);
          this.colseDialog();
          this.$message({
            message: "操作成功!",
            type: "success",
          });
          await this.initTable();
          break;
        case "cancel":
          this.colseDialog();

        default:
          break;
      }
    },
    /**
     *
     * @param item 移除老人联系人
     */
    handelButClick(item) {
      this.$confirm(
        "此操作将永久移除该联系方式,移除后,家属端该用户将不在关注当前老人基本信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          let requestData = {
            idList: [item.relationId],
          };
          await deleteContactPeople(requestData);
          await this.initTable();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    console.log("老人联系人:", this.data);
  },
};
</script>

<style lang="less" scoped>
#elderly-contacts {
  width: 100%;
  height: 100%;
  >.con{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    box-sizing: border-box;
    >.item{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 1px solid #e4e4e4;
        padding: 5px 13px;
        box-sizing: border-box;
        >.val1{
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        >.val{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
  }
}
</style>