<template>
  <div id="add-goods">
    <!-- 添加商品信息 -->
    <div class="con">
      <div style="border: 1px solid #ccc">
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 600px; overflow-y: hidden"
          v-model="html"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
        />
      </div>
      <div class="buts">
        <div class="but" @click="makeSure">确定</div>
        <div class="but" @click="cancel">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import {
  getGoodsOneById,
  addOrUpdateGoodsOneDetail,
} from "@/api/goods/detail.js";
export default Vue.extend({
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "<p></p>",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            server:
              process.env.VUE_APP_BASE_API + "nursinghomes/file/uploadFileEdit",
            fieldName: "file",
          },
          uploadVideo: {
            server:
              process.env.VUE_APP_BASE_API +
              "nursinghomes/file/uploadVideoEdit",
          },
        },
      },
      mode: "default", // or 'simple'
      id: "",
      sortList: [],
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },

    async initData() {
      let requestData = {
        id: this.id,
      };
      let resultData = await getGoodsOneById(requestData);
      this.html = resultData.content;
    },
    async makeSure() {
      let requestData = {
        id: this.id,
        content: this.html,
      };

      if (this.html.trim() == "") {
        this.$message({
          message: "内容不可为空!",
          type: "error",
        });
        return;
      }

      await addOrUpdateGoodsOneDetail(requestData);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      this.cancel();
    },
    cancel() {
      this.$store.dispatch("tagsView/delView", this.$router.currentRoute);
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : "";
    //存在就是chuaxu
    if (this.id != "") {
      await this.initData();
    }
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
#add-goods {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;

  > .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;

    > .buts {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      margin-top: 10px;

      > .but {
        width: auto;
        background-color: #3752ff;
        color: #fff;
        padding: 5px 60px;
        margin: 0 20px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>
