import request from "@/utils/request";
let baseUrl = "/nursinghomes/questionnaireRecord/";

/**
 * @description: 获取老人基础信息问卷作答记录
 * @return {*}
 */
export async function createAndGetBaseQuestionnaireRecordOne(data) {
    return await request({
      url: baseUrl + "createAndGetBaseQuestionnaireRecordOne",
      headers: {
        isToken: true,
      },
      method: "post",
      data: data,
      timeout: 20000,
    });
  }

/**
 * @description: 保存问卷作答记录（计算得分）
 * @return {*}
 */
export async function saveAndComputeScore(data) {
    return await request({
      url: baseUrl + "saveAndComputeScore",
      headers: {
        isToken: true,
      },
      method: "post",
      data: data,
      timeout: 20000,
    });
  }

/**
 * @description: 获取老人初始评价问卷作答记录
 * @return {*}
 */
export async function createAndGetInitQuestionnaireRecordOne(data) {
    return await request({
      url: baseUrl + "createAndGetInitQuestionnaireRecordOne",
      headers: {
        isToken: true,
      },
      method: "post",
      data: data,
      timeout: 20000,
    });
  }
