/**
 * 界面基础数据
 * @returns 
 */
export function baseData() {
    return {
        searchData: [
            {
                title: '老人姓名',
                value: "",
                type: "input",
                key: "name"
            }
        ],
        searchButton: [
            {
                name: "新增",
                icon: "el-icon-circle-plus-outline",
                key: "addElderly"
            }
        ],
        menuData: [
            {
                key: "name",
                title: "姓名",
                width: "80"
            },
            {
                key: "idCardNumber",
                title: "身份证号",
                width: ""
            },
            {
                key: "bedName",
                title: "床位号",
                width: ""
            },
            {
                key: "employeeName",
                title: "护工姓名",
                width: "120"
            },
            {
                key: "age",
                title: "年龄",
                width: "80"
            },
            {
                key: "height",
                title: "身高(cm)",
                width: "80"
            },
            {
                key: "weight",
                title: "体重(kg)",
                width: "80"
            },
            {
                key: "gender",
                title: "性别",
                width: "60"
            },
            {
                key: "dateOfBirth",
                title: "生日",
                width: "120"
            },
            {
                key: "status",
                title: "状态",
                width: "120",
                isChange: true
            },
            {
                key: "homeAddress",
                title: "家庭住址",
                width: ""
            },
        ],
        tableButton: [
            {title:"详情",key:"details"},
            {title:"联系人",key:"contacts"},
            {title:"删除",key:"del"},
            {title:"分配",key:"allocation"},
            {title:"基础情况",key:"questionnaire"},
            {title:"健康监测",key:"health"},
            {title:"日常护理",key:"care"},
        ]
    }
}

export function editBaseData() {
    return [
        {
            width: "45%",
            isNotNull: false,
            type: "img",
            title: "照片",
            key: "image",
            value: "",
            uploadData: {
                action: process.env.VUE_APP_BASE_API + "nursinghomes/file/uploadFile",
                headers: {
                    Authorization: process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
                },
                data: {
                    title: "老人头像",
                    key:"image"
                },
            },
        },
        {
            width: "45%",
            isNotNull: false,
            type: "files-pdf",
            title: "体检报告",
            key: "files",
            value: "",
            uploadData: {
                action: process.env.VUE_APP_BASE_API + "nursinghomes/file/uploadFile",
                headers: {
                    Authorization: process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
                },
                data: {
                    title: "体检报告",
                    key:"files"
                },
            },
        },
        {
            width: "45%",
            isNotNull: true,
            type: "input",
            title: "姓名",
            key: "name",
            value: ""
        },
        {
            width: "45%",
            isNotNull: true,
            type: "input",
            title: "身份证号",
            key: "idCardNumber",
            value: ""
        },
        {
            width: "45%",
            isNotNull: true,
            type: "date",
            title: "出生日期",
            key: "dateOfBirth",
            value: ""
        },
        {
            width: "45%",
            isNotNull: true,
            type: "input",
            title: "年龄",
            key: "age",
            value: ""
        },
        {
            width: "45%",
            isNotNull: true,
            type: "input",
            title: "身高(cm)",
            key: "height",
            value: ""
        },
        {
            width: "45%",
            isNotNull: true,
            type: "input",
            title: "体重(kg)",
            key: "weight",
            value: ""
        },
       
        {
            width: "45%",
            isNotNull: false,
            type: "input",
            title: "家庭住址",
            key: "homeAddress",
            value: ""
        },
        {
            width: "45%",
            isNotNull: false,
            type: "radio",
            title: "性别",
            key: "gender",
            value: "男",
            options:[
                {label:"男",value:"男"},
                {label:"女",value:"女"},
            ]
        },
    ]
}