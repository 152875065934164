<template>
  <div id="test">
    <div id="test_echart">
      <img :src="codeUrl" class="login-code-img" />
      <el-input v-model="code" placeholder="请输入验证码" @keyup.enter.native="login"></el-input>
    </div>
  </div>
</template>

<script>
import * as LoginData from "../api/login/login";
export default {
  data() {
    return {
      codeUrl: "",
      uuid: "",
      code: "",
    };
  },
  methods: {
    async login() {
      let requestData = {
        username: "admin",
        password: "admin123",
        uuid: this.uuid,
        code: this.code,
      };
      await LoginData.login(requestData);
    },
  },
  async mounted() {
    // let resultData = await LoginData.getCodeImg();
    // this.codeUrl = this.codeUrl = "data:image/gif;base64," + resultData.img;
    // this.uuid = resultData.uuid;
  },
};
</script>

<style lang="less" scoped>
#test {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  #test_echart {
    width: 1000px;
    height: 900px;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-code-img {
      height: 38px;
    }
  }
}
</style>