<template>
  <div id="store">
    <!-- 物品管理 -->
    <div class="search">
      <top-search
        :searchData="searchData"
        :searchButton="searchButton"
        @showSearchData=""
        showSearchData
        @handleClick="handleSearchClick"
      ></top-search>
    </div>
    <div class="content">
      <div class="table">
        <diy-table
          :tableData="tableData"
          :tableMenu="tableMenu"
          :model="model"
          :width="'320'"
          @showTableData="showTableData"
          @handleClick="handleTableClick"
        ></diy-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as TaskData from "./store.js";
import { getTaskListByPage,delTaskList } from "@/api/task/task.js";
export default {
  data() {
    return {
      searchData: TaskData.baseData().searchData,
      searchButton: TaskData.baseData().searchButton,
      tableData: [],
      tableMenu: TaskData.baseData().menuData,
      model: "task",
      pageData: {
        currentPage: 1,
        total: 3000,
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        page: 1, // 当前页码
      },
    };
  },
  methods: {
    /**
     * 初始化界面数据
     */
    async initTable() {
      let requestData = {
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.currentPage,
        startTime: "",
        endTime: "",
      };
      requestData = this.$total.array2json(this.searchData, requestData);
      if (!(requestData.time == null || requestData.time.length == 0)) {
        requestData.startTime = requestData.time[0];
        requestData.endTime = requestData.time[1];
      }
      let resultData = await getTaskListByPage(requestData);
      this.pageData.total = resultData.total;
      resultData.list.forEach((element) => {
        this.$set(element, "tableButton", TaskData.baseData().tableButton);
      });
      this.tableData = resultData.list;
    },
    /**
     * 顶部搜索界面的按钮事件
     * @param key
     */
    async handleSearchClick(key) {
      switch (key) {
        case "search":
          await this.initTable();
          break;

        default:
          break;
      }
    },
    /**
     * 表格中按钮点击事件
     * @param val
     */
    async handleTableClick(val) {
      let row = val.row;
      let key = val.key;
      let requestData = {};
      let resultData = {};
      switch (key) {
        case "details":
          // 任务详情
          let tag = this.$base.returnTag(
            "任务详情",
            { id: row.id },
            "/task/task-details",
            "/task/task-details",
            "任务管理"
          );
          this.$store.dispatch("tagsView/addView", tag);
          this.$router.push({
            path: tag.path,
            query: tag.query,
          });
          break;
        case "del":
          // 删除任务
          if (row.type != "system") {
            this.$message({
              message: "仅支持删除系统任务!",
              type: "error",
            });
            return;
          }
          requestData = {
            idList:[row.id]
          }
          await delTaskList(requestData)
          this.$message({
              message: "操作成功!",
              type: "success",
            });
            await this.initTable()
          break;

        default:
          break;
      }
    },
    showTableData(val) {
      this.tableData = val;
    },
    showSearchData(val) {
      this.searchData = val;
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTable();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTable();
    },
    // showDialogData(val) {
    //   this.dialogData.data = val;
    // },
    // colseDialog() {
    //   this.dialogData.flag = false;
    // },
  },
  async mounted() {
    await this.initTable();
  },
};
</script>

<style lang="less" scoped>
#store {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;

  > .search {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    min-height: 80px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    > .table {
      width: 100%;
      flex: 1;
      height: 0;
    }
    > .page {
      width: 100%;
      height: 50px;
    }
  }
}
</style>