import request from "@/utils/request";
let baseUrl = "/nursinghomes/goods/";

/**
 * @description: 分页查询商品列表
 * @return {*}
 */
export async function getGoodsListByPage(data) {
  return await request({
    url: baseUrl + "getGoodsListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除单条商品信息
 * @return {*}
 */
export async function delGoodsList(data) {
  return await request({
    url: baseUrl + "delGoodsList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 增加或修改单条商品信息
 * @return {*}
 */
export async function addOrUpdateGoodsOne(data) {
  return await request({
    url: baseUrl + "addOrUpdateGoodsOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条商品信息
 * @return {*}
 */
export async function getGoodsOneById(data) {
  return await request({
    url: baseUrl + "getGoodsOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取字典列表
 * @return {*}
 */
export async function getDictionaryList(data) {
  return await request({
    url: "/nursinghomes/dictionary/getDictionaryList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
