/**
 * 界面基础数据
 * @returns
 */
export function baseData() {
  return {
    searchData: [
      {
          title: '物品名称',
          value: "",
          type: "input",
          key: "name"
      }
    ],
    searchButton: [
      {
        name: "新增",
        icon: "el-icon-circle-plus-outline",
        key: "addItemCollection",
      },
    ],
    menuData: [
      {
        key: "name",
        title: "物品名称",
        width: "",
      },
      {
        key: "storageNumber",
        title: "库存",
        width: "",
      },
    ],
    tableButton: [
      { title: "修改", key: "edit" },
      { title: "删除", key: "del" },
    ],
  };
}

export function editBaseData() {
  return [
    {
      width: "40%",
      isNotNull: true,
      type: "input",
      title: "物品名称",
      key: "name",
      value: "",
    },
    {
      width: "40%",
      isNotNull: true,
      type: "input",
      title: "库存",
      key: "storageNumber",
      value: "",
    },
  ];
}
