<template>
  <div id="task-details">
    <!-- 任务详情 -->
    <div class="con">
        <edit-value
        :editValue="pageData"
        @showEditValueData="showEditValueData"
      ></edit-value>
    </div>
  </div>
</template>

<script>
import { getTaskOneById } from "@/api/task/task.js";
import * as TaskData from './task.js'
export default {
  data() {
    return {
      id: "",
      data:{
        titleName:"", // 任务名称	
        content:[], // 任务详情
        remark:"", // 备注
        type:"", // 任务类型 daily/system/mall   日常/系统/商城
        images:[], 
        status:"", // 状态 w/y/n 待执行/已完成/失败
        employeeName:"",  // 员工名称
        haveThumbs:"", // 点赞
        appraise:"", // 评价
        needTime:"", // 开始时间
        operTime:"", // 完成时间
      },
      pageData:TaskData.detailsData('daily')
    };
  },
  methods: {
    async initData() {
      let resultData = await getTaskOneById({ id: this.id });
      if (resultData.images == null) {
        resultData.images = []
      }
      if (resultData.content == null) {
        resultData.content = []
      }
      this.pageData = TaskData.detailsData(resultData.type)
      this.pageData = this.$total.json2array(this.pageData,resultData)
      console.log("界面数据",resultData,this.pageData);
      
    },
    
    showEditValueData(val) {
      this.pageData = val;
    },
  },
  async mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : "";
    await this.initData()
  },
};
</script>

<style lang="less" scoped>
#task-details {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  > .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;
  }
}
</style>