<template>
  <div id="elderly-index">
    <!-- 老人列表 -->
    <div class="search">
      <top-search
        :searchData="searchData"
        :searchButton="searchButton"
        @showSearchData="showSearchData"
        @handleClick="handleSearchClick"
      ></top-search>
    </div>
    <div class="content">
      <div class="table">
        <diy-table
          :tableData="tableData"
          :tableMenu="tableMenu"
          :model="model"
          :width="'360'"
          @showTableData="showTableData"
          @handleClick="handleTableClick"
        ></diy-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>

    <diy-dialog
      v-if="dialogData.flag"
      :dialogData="dialogData.data"
      :title="dialogData.title"
      :width="dialogData.width"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
    ></diy-dialog>

    <el-dialog
      :title="chartTitel"
      :visible.sync="dialogVisible"
      width="60%"
      @close="resetChart"
    >
      <el-date-picker
        v-model="selectedDate"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        @change="fetchChartData"
        style="margin-bottom: 20px"
      ></el-date-picker>
      <el-empty description="暂无监测数据" v-if="flagEcharts"> </el-empty>

      <LineChart :chartData="chartData" v-else />
    </el-dialog>
  </div>
</template>

<script>
import LineChart from "./health.vue";
import * as ElderlyData from "./elderly.js";
import {
  getElderlyListByPage,
  getSleepDeviceRecordECharts,
  delElderlyOne,
} from "@/api/elderly/elderly.js";
import { getGuardianList } from "@/api/elderly/relationUserElderly.js";
import { getUserListByPage } from "@/api/employ/employ.js";
import {
  getBedInfoByElderlyId,
  getRoomListByPage,
  distributeBedInfo,
} from "@/api/equipment/equipment.js";
export default {
  components: {
    LineChart,
  },
  data() {
    return {
      searchData: ElderlyData.baseData().searchData,
      searchButton: ElderlyData.baseData().searchButton,
      tableData: [],
      tableMenu: ElderlyData.baseData().menuData,
      model: "elderly",
      pageData: {
        currentPage: 1,
        total: 3000,
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        page: 1, // 当前页码
      },

      roomBedList: [],
      employList: [],

      dialogData: {
        tempData: {},
        flag: false,
        data: [],
        title: "添加护理周期",
        width: "40%",
        buttonList: [],
      },
      dialogVisible: false, // 控制对话框的显示
      chartData: {},
      chartTitel: "",
      selectedDate: [this.getToday(), this.getTomorrow()],
      flagEcharts: false,
      echartsId: "", //老人id查询健康监测时需要用
      updateDataInterval: null, // 用于存储定时器的引用
    };
  },
  methods: {
    /**
     * 初始化界面数据
     */
    async initTable() {
      let requestData = {
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.currentPage,
      };
      requestData = this.$total.array2json(this.searchData, requestData);
      let resultData = await getElderlyListByPage(requestData);
      this.pageData.total = resultData.total;
      resultData.list.forEach((element) => {
        this.$set(element, "tableButton", ElderlyData.baseData().tableButton);
      });
      this.tableData = resultData.list;
    },
    /**
     * 顶部搜索界面的按钮事件
     * @param key
     */
    handleSearchClick(key) {
      switch (key) {
        case "search":
          // 查询(默认所有界面均有)
          this.pageData.currentPage = 1;
          this.initTable();
          break;
        case "addElderly":
          // 新增老人信息
          this.jugeEditPage({}, "添加老人信息", "/elderly/add");
          break;

        default:
          break;
      }
    },
    jugeEditPage(query, title, path) {
      let tag = this.$base.returnTag(title, query, path, path, "老人管理");
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push({
        path: tag.path,
        query: query,
      });
    },
    /**
     * 表格中按钮点击事件
     * @param val
     */
    async handleTableClick(val) {
      let row = val.row;
      let key = val.key;
      let requestData = {};
      let resultData = {};
      switch (key) {
        case "details":
          // 查看老人详情
          this.jugeEditPage({ id: row.id }, "查看老人详情", "/elderly/add");
          break;
        case "questionnaire":
          // 基础问卷调查
          this.jugeEditPage(
            { id: row.id },
            "老人基础情况调查",
            "/elderly/questionnaireRecord"
          );
          break;
        case "care":
          // 日常护理
          this.jugeEditPage({ id: row.id }, "老人日常护理", "/elderly/care");
          break;
        case "allocation":
          // 老人信息分配
          resultData = await getBedInfoByElderlyId({ elderlyId: row.id });
          this.dialogData.tempData = row;
          this.openDialog("allocation", resultData);
          break;
        case "contacts":
          // 查看老人联系人
          resultData = await getGuardianList({ elderlyId: row.id });
          this.dialogData.tempData = row;
          this.openDialog("contacts", { elderlyId: row.id, data: resultData });
          break;
        case "health":
          // 健康监测
          this.echartsId = row.id;
          this.chartTitel = row.name + "--呼吸心率统计图";
          this.selectedDate=[this.getToday(), this.getTomorrow()];
          this.InitEchatsData();
          break;
        case "del":
          // 删除老人
          this.$confirm("此操作将永久删除该信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              try {
                let requestData = { id: row.id };
                await delElderlyOne(requestData);
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                await this.initTable();
              } catch (error) {
                // 这里捕获错误并进行处理
                this.$message({
                  type: "error",
                  message: "操作失败: " + error.message,
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          break;
      }
    },
    async InitEchatsData() {
      this.chartData = await getSleepDeviceRecordECharts({
        elderlyId: this.echartsId,
        time: this.selectedDate,
      });
      this.flagEcharts = this.chartData.xAxis.data.length > 0 ? false : true;
      this.dialogVisible = true;
      if (this.chartData.xAxis.data.length > 6) {
        this.chartData.xAxis.data.shift();
        this.chartData.series[0].data.shift();
      }

      // 更新图表
      // this.$refs.myChart.setOption(this.chartData);
      // 开始动态更新数据
      this.startDynamicUpdate();
    },
    startDynamicUpdate() {
      if (this.updateDataInterval) {
        clearInterval(this.updateDataInterval); // 清除之前的定时器
      }

      // 每隔一段时间更新数据
      this.updateDataInterval = setInterval(() => {
        // 这里可以调用接口获取新数据并更新 chartData
        this.InitEchatsData();
      }, 10000); // 每1秒更新一次数据
    },
    openDialog(key, data) {
      switch (key) {
        case "allocation":
          // 老人信息分配
          this.dialogData.title = "老人信息分配";
          this.dialogData.width = "30%";
          this.dialogData.data = [
            {
              title: "房间",
              type: "select-group",
              value: "",
              key: "bedId",
              options: this.roomBedList,
              isNotNull: true,
            },
            {
              title: "护工",
              type: "select",
              value: "",
              key: "employeeId",
              options: this.employList,
              isNotNull: true,
            },
          ];
          this.dialogData.buttonList = [
            { active: "allocation", text: "确定" },
            { active: "cancel", text: "取消" },
          ];
          if (data != null) {
            this.dialogData.data = this.$total.json2array(
              this.dialogData.data,
              data
            );
          }
          this.dialogData.flag = true;
          break;
        case "contacts":
          // 老人联系人
          this.dialogData.title = "老人信息分配";
          this.dialogData.width = "50%";
          this.dialogData.data = [
            {
              title: "",
              type: "elderly-contacts",
              value: data,
              isNotNull: true,
            },
          ];
          this.dialogData.buttonList = [{ active: "cancel", text: "关闭" }];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    async dialogOper(key) {
      let requestData = {};
      let mes = "";
      switch (key) {
        case "cancel":
          this.colseDialog();
          break;
        case "allocation":
          // 确认分配老人信息
          requestData = {
            elderlyId: this.dialogData.tempData.id,
          };
          requestData = this.$total.array2json(
            this.dialogData.data,
            requestData
          );
          mes = this.$total.judegArrayNull(this.dialogData.data, this.data);
          if (mes != "ok") {
            this.$message({
              message: mes + "不可为空!",
              type: "error",
            });
            return;
          }
          await distributeBedInfo(requestData);
          this.dialogData.flag = false;
          this.$message({
            message: "操作成功!",
            type: "success",
          });
          await this.initTable();
          await this.getBedList();
          break;

        default:
          break;
      }
    },
    async getBedList() {
      this.roomBedList = [];
      let rList = (await getRoomListByPage({})).list;
      rList.forEach((element) => {
        let child = [];
        if (element.children) {
          element.children.forEach((ele) => {
            let s = ele.sleepDeviceStatus == "y";
            let str =
              ele.elderlyId != null && ele.elderlyId != "" ? "(已住老人)" : "";
            let f = {
              label: "床位号:" + ele.bedNumber + str,
              value: ele.id,
              icon: s ? "el-icon-set-up" : "",
              isDisable: !(ele.elderlyId == null || ele.elderlyId == ""),
              showText: s ? "已安装睡眠仪!" : "",
            };
            child.push(f);
          });
        }
        let dd = {
          label: "房间号:" + element.name,
          value: element.id,
          children: child,
        };
        this.roomBedList.push(dd);
      });
    },
    getToday() {
      const today = new Date();
      return today.toISOString().split('T')[0]; // 格式化为 yyyy-MM-dd
    },
    getTomorrow() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().split('T')[0]; // 格式化为 yyyy-MM-dd
    },
    fetchChartData(newVal) {
      // 在此处理日期变化后的数据获取
    },
    openEchartsDialog() {
      this.dialogVisible = true; // 打开对话框
    },
    resetChart() {
      this.dialogVisible = false; // 关闭对话框时重置状态
      // this.selectedDate = ""; // 清空选择的日期
      // 可在这里添加其他需要处理的逻辑
      // 清除定时器
      clearInterval(this.updateDataInterval);
    },
    async fetchChartData() {
      this.chartData = await getSleepDeviceRecordECharts({
        elderlyId: this.echartsId,
        time: this.selectedDate,
      });
      this.flagEcharts = this.chartData.xAxis.data.length > 0 ? false : true;
      if (this.chartData.xAxis.data.length > 6) {
        this.chartData.xAxis.data.shift();
        this.chartData.series[0].data.shift();
      }
      // 开始动态更新数据
      // this.startDynamicUpdate();
    },
    showTableData(val) {
      this.tableData = val;
    },
    showSearchData(val) {
      this.searchData = val;
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTable();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTable();
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  async mounted() {
    await this.getBedList();

    this.employList = [];
    let eList = (await getUserListByPage({ type: "employee" })).list;
    eList.forEach((element) => {
      let dd = {
        id: element.id,
        value: element.userName,
      };
      this.employList.push(dd);
    });
    await this.initTable();
  },
};
</script>

<style lang="less" scoped>
#elderly-index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;

  > .search {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    min-height: 80px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    > .table {
      width: 100%;
      flex: 1;
      height: 0;
    }
    > .page {
      width: 100%;
      height: 50px;
    }
  }
}
</style>
