<template>
  <div id="goodsConfig">
    <div class="left">
      <div
        :class="{ line: true, 'is-active': activeMenu == item.key }"
        v-for="item in menuList"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="con">
      <div class="buts">
        <div class="but">新增</div>
      </div>
      <div class="list">
        <div class="line" v-for="item in data"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDictionaryList,
  saveOrUpdateDictionary,
  delDictionary,
} from "@/api/base/dictionary.js";
export default {
  data() {
    return {
      menuList: [
        { name: "商品类别", key: "goodsType" },
        { name: "商品配送范围", key: "area" },
      ],
      activeMenu: "goodsType",
      data: [],
    };
  },
  methods:{
    async initTable(){
        let requestData = {
            key :this.activeMenu
        }
       let resultData = await getDictionaryList(requestData)
       this.data = resultData
    },
  },
 async mounted(){
    await this.initTable()
 }
};
</script>

<style lang="less" scoped>
#goodsConfig {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px 20px;
  box-sizing: border-box;

  > .left {
    width: 14%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;
  }

  > .con {
    width: 0;
    flex: 1;
    margin-left: 20px;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;
  }
}
</style>