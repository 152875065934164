import request from "@/utils/request";
let baseUrl = "/nursinghomes/orders/";

/**
 * @description: 分页查询订单列表
 * @return {*}
 */
export async function getOrdersListByPage(data) {
  return await request({
    url: baseUrl + "getOrdersListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除单条商品信息
 * @return {*}
 */
export async function delOrdersList(data) {
  return await request({
    url: baseUrl + "delOrdersList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 核销订单
 * @return {*}
 */
export async function checkConsume(data) {
  return await request({
    url: baseUrl + "checkConsume",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条订单
 * @return {*}
 */
export async function getGoodsOneById(data) {
  return await request({
    url: baseUrl + "getGoodsOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}