<template>
  <div id="line-chart" ref="lineChart" style="width: 100%; height: 400px"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LineChart",
  props: {
    chartData: {
      // 接收 chartData 作为 prop
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  mounted() {
    this.initChart();
    window.addEventListener("resize", this.onResize);
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.renderChart(); // 当 chartData 变化时重新渲染图表
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.lineChart);
      this.renderChart();
    },
    renderChart() {
      const option = {
        title: {
          text: "健康监测",
          left: "center",
          top: 10,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: this.chartData.xAxis,
        yAxis: {
          type: "value",
        },
        series: this.chartData.series,
      };

      this.chartInstance.setOption(option);
    },
    onResize() {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    },
  },
};
</script>

<style scoped>
#line-chart {
  border: 1px solid #eaeaea; /* 可选的边框样式 */
  border-radius: 4px; /* 可选的圆角样式 */
}
</style>
