import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from "@/store";

Vue.prototype.$store = store
import "./assets/css/base.css"

import "./assets/fonts/fonts.css" //本地字体
import "./assets/iconfont/iconfont.css" // 阿里巴巴矢量图标库
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueAxios, axios);

import request from "./utils/request.js"
import formatTime from "./utils/formatTime.js"
import * as total from "./utils/total.js"
import * as base from "./utils/base.js"

Vue.prototype.$request = request
Vue.prototype.$formatTime = formatTime
Vue.prototype.$total = total
Vue.prototype.$base = base

Vue.prototype.$copyright = "Copyright © 安徽弛振科技有限公司"

//全局组件
import "@/components/globalRegisterComponents";


import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
    Options: {
        "inline": true,
        "button": true,
        "navbar": true,
        "title": false,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')