<template>
  <div class="order-details">
    <h2 class="title">订单详情</h2>
    <div class="address-info card">
      <h4>客户信息</h4>
      <p><strong>姓名:</strong> {{ address.name }}</p>
      <p><strong>电话:</strong> {{ address.tel }}</p>
      <p>
        <strong>地址:</strong> {{ address.address }}
        {{ address.DetailsAddress }}
      </p>
      <p><strong>性别:</strong> {{ address.sex === 1 ? "男" : "女" }}</p>
      <!-- <p><strong>是否选中:</strong> {{ address.checked ? "是" : "否" }}</p> -->
    </div>

    <div class="product-info card">
      <h4>订单商品</h4>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item
          v-for="(product, index) in products"
          :key="product.id"
          :name="index.toString()"
          :title="product.name"
          class="product-card"
        >
          <template #title>
            <div class="collapse-title">
              <img :src="product.imgurl" alt="商品图片" class="product-image" />
              <span class="product-name">{{ product.name }}</span>
            </div>
          </template>
          <div class="product-details">
            <p class="product-price">
              <strong>价格:</strong> ￥{{ product.price }}
            </p>
            <p class="product-quantity">
              <strong>数量:</strong> {{ product.number }}
            </p>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { getGoodsOneById } from "@/api/order/order.js";
export default {
  data() {
    return {
      activeName: "0",
      products: [],
      address: {
        id: 1,
        sex: 1,
        tel: "",
        name: "",
        address: "",
        checked: true,
        DetailsAddress: "",
      },
    };
  },
  methods: {
    async initData() {
      let requestData = {
        id: this.id,
      };
      let resultData = await getGoodsOneById(requestData);
      this.products = resultData.orderList;
      console.log(this.products);
      this.address = resultData.address;
    },
  },
  async mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : "";
    if (this.id != "") {
      await this.initData();
    }
  },
};
</script>

<style lang="less" scoped>
@primary-color: #ff4d4f;
@background-color: #f4f7fa;
@card-background: #fff;
@border-color: #e0e0e0;

.order-details {
  width: 99.2%;
  height: 100%;
  overflow-y: auto;
  padding: 10px 20px;
  box-sizing: border-box;
  padding: 20px;
  font-family: Arial, sans-serif;
  // background-color: @background-color;
  border-radius: 10px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  // background-color: #fff;
  letter-spacing: 2px;

  .title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    background-color: @card-background;
    border-radius: 4px;
    padding: 8px 0;
  }

  .card {
    background-color: @card-background;
    border-radius: 10px;
    padding: 15px;
    margin: 10px 0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

    strong {
      margin-right: 10px;
    }
    p {
      margin-bottom: 10px;
    }

    // .product-card {
    //   display: flex;
    //   align-items: center;
    //   padding: 10px 0;
    //   border-bottom: 1px solid @border-color;

    //   &:last-child {
    //     border-bottom: none; // 去掉最后一项的下边框
    //   }

    //   .product-image {
    //     width: 180px;
    //     height: 100px;
    //     border-radius: 8px;
    //     margin-right: 15px;
    //     box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    //   }

    //   .product-details {
    //     flex: 1;
    //     display: flex;
    //     flex-direction: column;

    //     .product-name {
    //       color: #555;
    //       margin: 0;
    //     }

    //     .product-price {
    //       color: @primary-color;
    //     }

    //     .product-quantity {
    //       color: #666;
    //     }
    //   }
    // }
  }
  .collapse-title {
    display: flex;
    align-items: center;

    .product-image {
      width: 220px;
      height: 180px;
      border-radius: 5px;
      margin-right: 10px;
    }
    .product-name {
      font-size: 26px;
    }
  }

  .product-price {
    color: @primary-color;
  }

  .product-quantity {
    color: #666;
  }
  /deep/.el-collapse-item__header {
    min-height: 200px;
  }
}
</style>
