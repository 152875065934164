/**
 * 界面基础数据
 * @returns
 */
export function baseData() {
  return {
    searchData: [
      {
          title: '分类名称',
          value: "",
          type: "input",
          key: "name"
      }
    ],
    searchButton: [
      {
        name: "新增",
        icon: "el-icon-circle-plus-outline",
        key: "addSort",
      },
    ],
    menuData: [
      {
        key: "name",
        title: "分类名称",
        width: "",
      },
      {
        key: "des",
        title: "分类描述",
        width: "",
      },
      {
        key: "imgurl",
        title: "分类封面",
        width: "",
        showImage: true,
      },
    ],
    tableButton: [
      { title: "修改", key: "edit" },
      { title: "删除", key: "del" },
    ],
  };
}

export function editBaseData() {
  return [
    {
      width: "70%",
      isNotNull: true,
      type: "img",
      title: "分类封面",
      key: "imgurl",
      value: "",
      uploadData: {
        action: process.env.VUE_APP_BASE_API + "nursinghomes/file/uploadFile",
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "分类封面",
          key: "imgurl",
        },
      },
    },
    {
      width: "40%",
      isNotNull: true,
      type: "input",
      title: "分类名称",
      key: "name",
      value: "",
    },
    {
      width: "40%",
      isNotNull: true,
      type: "input",
      title: "分类描述",
      key: "des",
      value: "",
    },
  ];
}
