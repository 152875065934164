<template>
  <div id="diy-dialog">
    <div class="content" @click.stop="clickNull" :style="{ width: width }">
      <div class="colse" @click="colseDialog">
        <i class="el-icon-close"></i>
      </div>
      <div class="title">{{ title }}</div>

      <div class="dialog-content">
        <div
          class="dialog-content-line"
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="dialog-content-line-title" v-if="item.title">
            <div class="xx" v-if="item.isNotNull">*</div>
            {{ item.title }}:
          </div>

          <div class="dialog-content-line-value" v-if="item.type == 'input'">
            <el-input
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
            ></el-input>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'inputdisabled'"
          >
            <el-input
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
              :disabled="item.flag"
            ></el-input>
          </div>

          <div
            class="dialog-content-line-value flex-wrap"
            v-if="item.type == 'input-change'"
          >
            <el-input
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
              @change="
                (e) => {
                  changeInputNumber(e, item);
                }
              "
            ></el-input>

            <div class="val">
              <div class="vv" v-for="va in item.numData">
                <el-input v-model="va.value" clearable></el-input>
              </div>
            </div>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'input_psw'"
          >
            <el-input v-model="item.value" show-password></el-input>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'text'">
            {{ item.value }}
          </div>
          <div
            class="dialog-content-line-value height_auto"
            v-if="item.type == 'text_left'"
          >
            {{ item.value }}
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'radio'">
            <el-radio-group
              v-model="item.value"
              @change="changeFlag"
              :disabled="item.flag"
            >
              <el-radio
                :label="it.value"
                v-for="(it, ii) in item.options"
                :key="ii"
                >{{ it.label }}</el-radio
              >
            </el-radio-group>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'input_carriage'"
          >
            <el-input
              v-model="item.value"
              :placeholder="item.placeholder"
              @keyup.enter.native="enter"
            ></el-input>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'textarea'">
            <el-input
              type="textarea"
              :rows="2"
              v-model="item.value"
              :placeholder="item.placeholder"
            ></el-input>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'select'">
            <el-select
              v-model="item.value"
              filterable
              :placeholder="item.placeholder"
              :disabled="item.flag"
            >
              <el-option
                v-for="it in item.options"
                :key="it.id"
                :label="it.value"
                :value="it.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'select-group'"
          >
            <el-select
              v-model="item.value"
              filterable
              :placeholder="item.placeholder"
              :disabled="item.flag"
            >
              <el-option-group
                v-for="group in item.options"
                :key="group.value"
                :label="group.label"
              >
                <el-option
                  v-for="it in group.children"
                  :key="it.id"
                  :label="it.label"
                  :value="it.value"
                  :disabled="it.isDisable"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    {{ it.label }}
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="it.showText"
                      placement="top"
                    >
                      <i
                        :class="it.icon"
                        style="color: #6bc229; font-size: 16px"
                      ></i>
                    </el-tooltip>
                  </div>
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'select-html'"
          >
            <el-select
              v-model="item.value"
              filterable
              :placeholder="item.placeholder"
              :disabled="item.flag"
            >
              <el-option
                v-for="it in item.options"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
                <span v-html="it.label"></span>
              </el-option>
            </el-select>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'select-obj'"
          >
            <el-select
              v-model="item.value"
              :placeholder="item.placeholder"
              filterable
              value-key="value"
            >
              <el-option
                v-for="it in item.options"
                :key="it.value"
                :label="it.label"
                :value="it"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'select-object'"
          >
            <el-select
              v-model="item.value"
              :placeholder="item.placeholder"
              multiple
              filterable
              value-key="value"
            >
              <el-option
                v-for="it in item.options"
                :key="it.value"
                :label="it.label"
                :value="it"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'select-object-collapse'"
          >
            <el-select
              v-model="item.value"
              :placeholder="item.placeholder"
              multiple
              filterable
              collapse-tags
              value-key="value"
            >
              <el-option
                v-for="it in item.options"
                :key="it.value"
                :label="it.label"
                :value="it"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'select-search'"
          >
            <el-select
              v-model="item.value"
              :placeholder="item.placeholder"
              filterable
            >
              <el-option
                v-for="it in item.options"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="dialog-content-line-value div-flex-wrap"
            v-if="item.type == 'select-bk'"
          >
            <!-- 边框选择界面 -->
            <div
              :class="{
                'value-item': true,
                'value-item-avtive': item.value == it.value,
              }"
              v-for="(it, ii) in item.options"
              :key="ii"
              @click="changeValue(item, it)"
            >
              <i :class="it.icon"></i>
              {{ it.label }}
            </div>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'time'">
            <el-date-picker
              v-model="item.value"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'time_date'"
          >
            <el-date-picker
              v-model="item.value"
              type="date"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'times'">
            <el-date-picker
              v-model="item.value"
              type="datetimerange"
              :picker-options="item.pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'add-tip-select-search'"
          >
            <el-select
              v-model="item.value"
              :placeholder="item.placeholder"
              filterable
              @change="changeHandle(item.value, index)"
              value-key="value"
            >
              <el-option
                v-for="it in item.options"
                :key="it.value"
                :label="it.label"
                :value="it"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'tips'">
            <el-tag
              v-for="tag in item.value"
              :key="tag.value"
              closable
              @close="handleClose(tag, index, 0)"
            >
              {{ tag.label }}
            </el-tag>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'tips_text'"
          >
            <el-tag
              v-for="tag in item.value"
              :key="tag"
              closable
              @close="handleClose(tag, index, 1)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm(index)"
              @blur="handleInputConfirm(index)"
            >
            </el-input>
            <el-button
              type="success"
              class="button-new-tag"
              @click="showInput"
              size="mini"
              >+</el-button
            >
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'tips_select'"
          >
            <el-tag
              v-for="tag in item.value"
              :key="tag"
              closable
              @close="handleClose(tag, index)"
            >
              {{ tag }}
            </el-tag>
            <el-select
              class="input-new-tag"
              v-if="inputVisible"
              filterable
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @change="handleInputConfirm(index)"
            >
              <el-option
                v-for="itt in item.options"
                :key="itt.value"
                :label="itt.label"
                :value="itt.value"
              >
              </el-option>
            </el-select>
            <el-button
              type="success"
              class="button-new-tag"
              @click="showInput"
              size="mini"
              >+</el-button
            >
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'add-but'">
            <el-button
              type="success"
              @click="dialogOper(item.active)"
              size="mini"
              >{{ item.text }}</el-button
            >
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'button'">
            <div v-if="item.value.length > 0">
              <el-tag v-for="tag in item.value" :key="tag.id">{{
                tag.text
              }}</el-tag>
            </div>
            <el-button
              type="success"
              @click="dialogOper(item.active)"
              size="mini"
              >{{ item.text }}</el-button
            >
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'table'">
            <!-- 可选择的表格 -->
            <el-table
              :data="item.value"
              border
              style="width: 100%"
              height="600"
            >
              <el-table-column type="index" label="序号" width="55">
              </el-table-column>
              <el-table-column
                :prop="menu.key"
                :label="menu.name"
                v-for="(menu, ii) in item.menu"
                :key="ii"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            class="dialog-content-line-value"
            v-if="item.type == 'elderly-contacts'"
          >
            <!-- 老人联系人 -->
            <elderly-contacts
              :id="item.value.elderlyId"
              :contactsData="item.value.data"
            ></elderly-contacts>
          </div>
        </div>
      </div>
      <div class="dialog-buts">
        <div
          class="dialog-but"
          v-for="(item, index) in buttonList"
          :key="index"
          @click="dialogOper(item.active)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElderlyContacts from "./elderlyContacts.vue";
export default {
  components: { ElderlyContacts },
  name: "diy_dialog",
  props: {
    dialogData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "40%",
    },
    buttonList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: this.dialogData,
      inputVisible: false,
      inputValue: "",
    };
  },
  watch: {
    dialogData() {
      this.data = this.dialogData;
    },
    data(newValue) {
      this.$emit("showDialogData", this.data);
    },
  },
  methods: {
    colseDialog() {
      this.$emit("colseDialog");
      // this.dialogOper("cancel");
    },
    clickNull() {},
    dialogOper(active) {
      this.$emit("dialogOper", active);
    },
    showAddUser(value) {
      this.data.forEach((element) => {
        if (element.type == "add-user") {
          element.value = value;
        }
      });
    },
    changeFlag() {},

    showInput() {
      this.inputVisible = true;
    },
    handleInputConfirm(index) {
      console.log(this.data, index);
      let inputValue = this.inputValue;
      if (inputValue) {
        this.data[index].value.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleClose(tag, index) {
      this.data[index].value.splice(this.data[index].value.indexOf(tag), 1);
    },
    changeValue(item, data) {
      item.value = data.value;
    },
    showPaper(value) {
      this.$emit("showPaper", value);
    },
    handleClickChangePaper(id) {
      this.$emit("handleClickChangePaper", id);
    },
    changeInputNumber(val, item) {
      if (!this.$total.judgeStringIsNumber(val)) {
        this.$message({
          type: "error",
          message: "请输入数字!",
        });
        item.value = "";
        return;
      }
      item.numData = [];
      let num = Number.parseInt(val);
      for (let index = 0; index < num; index++) {
        let dd = {
          value: index + 1,
          id: null,
        };
        item.numData.push(dd);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#diy-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000036;
  z-index: 99;
  text-align: center;

  > .content {
    width: 65%;
    height: auto;
    max-height: 80vh;
    background-color: #fff;
    margin: 100px auto;
    margin-bottom: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    > .colse {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    > .title {
      width: 100%;
      padding-left: 20px;
      border-left: 5px double #333;
      box-sizing: border-box;
      display: flex;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .dialog-content {
      width: 100%;
      // height: 0;
      // flex: 1;
      min-height: 50px;
      max-height: 900px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      &::-webkit-scrollbar {
        width: 1px;
      }

      .dialog-content-line {
        display: flex;
        width: 95%;
        height: auto;
        //  min-height: 40px;
        //max-height: 300px;
        //  overflow-y: auto;
        margin: 5px;

        &::-webkit-scrollbar {
          width: 1px;
        }

        .dialog-content-line-title {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          margin-right: 5px;
          min-height: 30px;

          > .xx {
            color: #f00;
            font-size: 20px;
            margin-top: 4px;
            margin-right: 8px;
          }
        }

        .dialog-content-line-value {
          flex: 1;
          height: 100%;
          width: 0;
          max-height: 900px;
          display: flex;
          align-items: center;
          /deep/.el-select {
            width: 100%;
          }
          > .val {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            > .vv {
              width: 50%;
              padding: 8px;
              box-sizing: border-box;
            }
          }

          /deep/.el-date-editor.el-input,
          /deep/.el-date-editor.el-input__inner {
            width: 100%;
          }

          .dialog-content-line-value-active {
            flex: 1;
          }

          .dialog-content-line-value-img {
            margin: 0 2px;
          }

          > .value-item {
            width: 120px;
            height: 120px;
            background-color: #f7f7f7bd;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            margin: 3px;
            flex-direction: column;
            border: 1px solid #cecece;
            border-radius: 3px;
            cursor: pointer;

            i {
              margin-bottom: 4px;
            }

            transition: 0.3s;

            &:hover {
              border-color: #9ada8b;
              background-color: #9ada8b7a;
              color: #37a81d;
            }
          }

          > .value-item-avtive {
            border-color: #9ada8b;
            background-color: #9ada8b7a;
            color: #37a81d;
          }
        }
      }
    }

    .dialog-buts {
      width: 100%;
      height: 50px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .dialog-but {
        width: auto;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #3752ff;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
        padding: 10px 25px;
        box-sizing: border-box;
        margin: 0 5px;
      }

      .dialog-but:hover {
        cursor: pointer;
      }
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .flex-column {
      flex-direction: column;
    }

    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }

    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }

    .height_auto {
      height: auto !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      font-size: 13px;
      line-height: 28px;
      color: rgb(0, 140, 255);
    }

    .tip_tip {
      margin-left: 5px;
    }

    .el-cascader {
      width: 100%;
    }

    .el-tag {
      margin: 0 5px;
    }
  }
}
</style>
