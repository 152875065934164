import request from "@/utils/request";
let baseUrl = "/nursinghomes/task/";

/**
 * @description: 分页查询任务列表
 * @return {*}
 */
export async function getTaskListByPage(data) {
  return await request({
    url: baseUrl + "getTaskListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条任务信息
 * @return {*}
 */
export async function getTaskOneById(data) {
  return await request({
    url: baseUrl + "getTaskOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取所有护工
 * @return {*}
 */
export async function getAllEmployeeList(data) {
  return await request({
    url: "/nursinghomes/dictionary/getAllEmployeeList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 增加或修改单条任务信息（系统任务/商场任务）
 * @return {*}
 */
export async function addOrUpdateTaskOne(data) {
  return await request({
    url: "/nursinghomes/task/addOrUpdateTaskOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}


/**
 * @description: 获取单条任务信息
 * @return {*}
 */
export async function getTaskOneByOrderId(data) {
  return await request({
    url: baseUrl + "getTaskOneByOrderId",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}


/**
 * @description: 删除任务（系统任务）
 * @return {*}
 */
export async function delTaskList(data) {
  return await request({
    url: baseUrl + "delTaskList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}