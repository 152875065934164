<template>
  <div id="equipment">
    <!-- 设备管理 -->
    <div class="con">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="房间信息" name="second"></el-tab-pane>

        <el-tab-pane label="房间列表" name="first"></el-tab-pane>
      </el-tabs>
      <div class="cc customScrollBar" v-show="activeName === 'first'">
        <el-collapse
          accordion
          v-if="tableData.length > 0"
          v-model="activeNames"
        >
          <template v-for="(item, index) in tableData">
            <el-collapse-item :name="index.toString()">
              <template slot="title">
                {{ item.name }}
              </template>
              <div class="conn">
                <div class="title">
                  房间号:{{ item.name }}
                  <i
                    class="el-icon-edit-outline"
                    style="margin-left: 8px; color: #4568e6"
                    @click="handleButClick('edit', item)"
                  ></i>
                  <!-- <i
                    class="el-icon-delete"
                    style="margin-left: 8px; color: #e66a6a"
                    @click="handleButClick('del', item)"
                  ></i> -->
                  <i
                    class="el-icon-circle-plus"
                    style="margin-left: 8px; color: #4568e6"
                    @click="handleButClick('add', item)"
                  ></i>
                </div>
                <div class="imgs" v-viewer="{ movable: false }">
                  <img
                    :src="img"
                    v-for="img in item.images"
                    alt=""
                    style="width: 160px; height: 90px"
                  />
                </div>
                <div class="tab">
                  <el-table :data="item.children" style="width: 60%" border>
                    <el-table-column
                      prop="bedNumber"
                      label="床位号"
                      width="230"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="sleepDeviceNumber"
                      label="睡眠仪编号"
                      width="230"
                    >
                      <template slot-scope="scope">
                        <span>{{
                          scope.row.sleepDeviceNumber == null ||
                          scope.row.sleepDeviceNumber == ""
                            ? "暂未绑定睡眠仪"
                            : scope.row.sleepDeviceNumber
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="" label="操作">
                      <template slot-scope="scope">
                        <el-button
                          @click="handleButClick('editbed', scope.row)"
                          type="text"
                          size="small"
                          >编辑</el-button
                        >
                        <el-button
                          @click="handleButClick('delbed', scope.row)"
                          type="text"
                          size="small"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-collapse-item>
          </template>
        </el-collapse>
        <el-empty description="暂无房间" v-else>
          <el-button type="primary" @click="handleButClick('add', {})"
            >添加房间</el-button
          >
        </el-empty>
      </div>
      <div class="cc customScrollBar" v-show="activeName === 'second'">
        <div
          class="item"
          v-for="item in roomdata"
          :style="{ width: item.width }"
        >
          <div class="title">
            <i class="el-icon-star-on" v-if="item.isNotNull"></i>
            <div class="va">
              {{ item.title }}
            </div>
          </div>
          <div class="val">
            <el-input
              v-if="item.type == 'input'"
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
            ></el-input>
            <div class="AA" v-else-if="item.type == 'imgs'">
              <el-upload
                class="upload-demo"
                :show-file-list="false"
                :action="item.uploadData.action"
                :headers="item.uploadData.headers"
                :data="item.uploadData.data"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :on-remove="(file) => handleRemove(file, item)"
              >
                <div class="imgOUter">
                  <div class="btn">
                    <el-button size="small" type="primary"
                      ><i slot="default" class="el-icon-plus"></i
                      >上传图片</el-button
                    >
                  </div>

                  <div class="roomImg" v-for="(it, i) in item.value" :key="i">
                    <img :src="it" alt="" />
                    <span class="actions">
                      <span
                        @click="
                          (event) => {
                            event.stopPropagation();
                            handlePictureCardPreview({ url: it });
                          }
                        "
                        ><i class="el-icon-zoom-in"></i
                      ></span>
                      <span
                        @click="
                          (event) => {
                            event.stopPropagation();
                            handleRemove(it, item, i);
                          }
                        "
                      >
                        <i class="el-icon-delete"></i
                      ></span>
                    </span>
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="buts">
          <div v-if="id == ''" class="but" @click="makeSure">添加房间</div>
          <div v-else class="but" @click="makeSure">修改房间</div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <diy-dialog
      v-if="dialogData.flag"
      :dialogData="dialogData.data"
      :title="dialogData.title"
      :width="dialogData.width"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
    ></diy-dialog>
  </div>
</template>

<script>
import * as EquipmentData from "./equipment.js";
import {
  getRoomListByPage,
  addOrUpdateBedList,
  updateRoomInfo,
  delBedOne,
  getBedOneById,
  addOrUpdateBedOne,
} from "@/api/equipment/equipment.js";
export default {
  data() {
    return {
      activeName: "first",
      // searchData: EquipmentData.baseData().searchData,
      // searchButton: EquipmentData.baseData().searchButton,
      tableData: [],
      // tableMenu: EquipmentData.baseData().menuData,
      roomdata: EquipmentData.editBaseData(),
      id: "",
      model: "equipment",
      activeNames: "0",
      pageData: {
        currentPage: 1,
        total: 3000,
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        page: 1, // 当前页码
      },

      dialogData: {
        tempData: {},
        flag: false,
        data: [],
        title: "添加护理周期",
        width: "40%",
        buttonList: [],
      },
      inputValue: "", // 存储用户输入的描述信息
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [], // 存储上传的文件列表
    };
  },
  methods: {
    EditSure() {},
    beforeAvatarUpload(val) {
      return true; // 返回 true 以继续上传
    },
    handleAvatarSuccess(response, file, fileList, item) {
      if (response.code == "200") {
        let resultData = response.result;
        this.roomdata.forEach((element) => {
          if (element.key == resultData.key) {
            element.value.push(resultData.path);
          }
        });
      }
    },
    handleRemove(file, item, i) {
      console.log(file, item);
      const index = item.value.indexOf(file);
      if (index !== -1) {
        item.value.splice(i, 1); // 从该 item's file list 中移除文件
      }
      item.value.splice(i, 1); // 从该 item's file list 中移除文件
    },
    handlePictureCardPreview(url) {
      console.log(url);
      this.dialogImageUrl = url.url;
      this.dialogVisible = true;
    },

    async initTable() {
      let resultData = await getRoomListByPage({});
      this.tableData = resultData.list;
    },
    handleClick(tab, event) {
      this.id = "";
      this.roomdata = EquipmentData.editBaseData();
    },
    async handleButClick(key, item) {
      switch (key) {
        case "edit":
          // 编辑房间
          this.activeName = "second";
          this.id = item.id;
          let daa = {
            images: item.images ? item.images : [],
            roomNumber: item.name,
          };
          console.log(item);
          this.roomdata = this.$total.json2array(this.roomdata, daa);
          // this.dialogData.tempData = item;
          // this.openDialog("edit");
          // let daa = {
          //   roomNumber: item.name,
          //   roomNum: item.children.length,
          // };
          // this.dialogData.data = this.$total.json2array(
          //   this.dialogData.data,
          //   daa
          // );
          // let numData = []
          // item.children.forEach(element => {
          //   let dd = {
          //     value:element.bedNumber,
          //     id:element.id
          //   }
          //   numData.push(dd)
          // });
          // this.dialogData.data[1].numData = numData
          // this.dialogData.flag = true;
          break;
        case "del":
          // 删除房间
          this.$message({
            type: "warning",
            message: "不可删除!",
          });

          break;
        case "add":
          // 添加房间
          this.id = item.id;

          this.dialogData.tempData = {};
          this.openDialog("add");
          this.dialogData.flag = true;
          break;

        case "delbed":
          // 删除床位
          this.$confirm("此操作将永久删除所选的信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let requestData = { id: item.id };
              await delBedOne(requestData);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              await this.initTable();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        case "editbed":
          // 编辑床位
          console.log("object :>> ", item);

          this.dialogData.tempData = item;
          this.openDialog("editbed", item.id);
          // this.dialogData.data = this.$total.json2array(
          //   this.dialogData.data,
          //   daa
          // );

          this.dialogData.flag = true;

        default:
          break;
      }
    },
    async openDialog(key, id) {
      switch (key) {
        case "add":
          this.dialogData.title = "添加床位";
          this.dialogData.data = [
            // {
            //   title: "房间号",
            //   type: "input",
            //   value: "",
            //   key: "roomNumber",
            //   isNotNull: true,
            // },
            {
              title: "床位数量",
              type: "input-change",
              value: "",
              key: "roomNum",
              isNotNull: true,
              numData: [],
            },
          ];
          this.dialogData.buttonList = [
            { active: "makesureAdd", text: "确认" },
            { active: "cancel", text: "取消" },
          ];
          break;

        case "edit":
          this.dialogData.title = "编辑房间";
          this.dialogData.data = [
            {
              title: "房间号",
              type: "input",
              value: "",
              key: "roomNumber",
              isNotNull: true,
            },
            // {
            //   title: "床位数量",
            //   type: "input-change",
            //   value: "",
            //   key: "roomNum",
            //   isNotNull: true,
            //   numData: [],
            // },
          ];
          this.dialogData.buttonList = [
            { active: "makesureEdit", text: "确认" },
            { active: "cancel", text: "取消" },
          ];
          break;
        case "editbed":
          this.dialogData.title = "编辑床位";
          this.dialogData.data = [
            {
              title: "床位号",
              type: "inputdisabled",
              value: "",
              key: "bedNumber",
              isNotNull: false,
              flag: true,
            },
            {
              title: "睡眠仪编号",
              type: "input",
              value: "",
              key: "sleepDeviceNumber",
              isNotNull: true,
              numData: [],
            },
            {
              title: "睡眠仪状态",
              placeholder: "请选择睡眠仪状态",
              type: "select",
              value: "",
              key: "sleepDeviceStatus",
              isNotNull: true,
              options: [
                {
                  id: "y",
                  value: "正常",
                },
                {
                  id: "n",
                  value: "停用",
                },
              ],
            },
            {
              title: "床的状态",
              placeholder: "请选择床的状态",
              type: "select",
              value: "",
              key: "status",
              isNotNull: true,
              options: [
                {
                  id: "y",
                  value: "正常",
                },
                {
                  id: "n",
                  value: "停用",
                },
              ],
            },
          ];
          this.dialogData.buttonList = [
            { active: "makesureEditBed", text: "确认" },
            { active: "cancel", text: "取消" },
          ];
          let requestData = {
            id: id,
          };
          let resultData = await getBedOneById(requestData);
          this.dialogDatadata = this.$total.json2array(
            this.dialogData.data,
            resultData
          );
          break;

        default:
          break;
      }
    },
    async dialogOper(key) {
      let requestData = {};
      switch (key) {
        case "cancel":
          this.colseDialog();
          break;
        case "makesureAdd":
          // 确定添加房间信息
          let dataList = [];
          this.dialogData.data[0].numData.forEach((element) => {
            let dd = {
              bedNumber: element.value,
              bedImg: [],
              status: "y",
            };
            dataList.push(dd);
          });
          requestData = {
            id: this.id,
            dataList: dataList,
          };
          requestData = this.$total.array2json(
            this.dialogData.data,
            requestData
          );
          await addOrUpdateBedList(requestData);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.id = "";
          await this.initTable();
          this.colseDialog();
          break;

        case "makesureEdit":
          // 确定添加房间信息
          // let dataList1 = [];
          // this.dialogData.data[1].numData.forEach((element) => {
          //   let dd = {
          //     bedNumber: element.value,
          //     id:element.id,
          //     bedImg: [],
          //     status: "y",
          //   };
          //   dataList1.push(dd);
          // });
          requestData = {
            id: this.dialogData.tempData.id,
            // dataList: dataList1,
          };
          requestData = this.$total.array2json(
            this.dialogData.data,
            requestData
          );
          await updateRoomInfo(requestData);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          await this.initTable();
          this.colseDialog();
          break;
        case "makesureEditBed":
          //确定修改床位
          requestData = {
            id: this.dialogData.tempData.id,
          };
          requestData = this.$total.array2json(
            this.dialogData.data,
            requestData
          );
          await addOrUpdateBedOne(requestData);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          await this.initTable();
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },

    showEditValueData(val) {
      this.data = val;
    },
    //添加房间信息
    async makeSure() {
      let requestData = {};
      if (this.id != "") {
        requestData.id = this.id;
      } else {
        requestData.id = "";
      }
      let mes = this.$total.judegArrayNull(this.roomdata);
      if (mes != "ok") {
        this.$message({
          message: mes + "不可为空!",
          type: "error",
        });
        return;
      }
      requestData = this.$total.array2json(this.roomdata, requestData);
      await updateRoomInfo(requestData);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      this.activeName = "first";
      //将id置空 初始化
      this.id = "";
      this.roomdata = EquipmentData.editBaseData();
      await this.initTable();
    },
  },
  async mounted() {
    await this.initTable();
  },
};
</script>

<style lang="less" scoped>
#equipment {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  > .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;
    > .el-tabs {
      width: 100%;
    }
    > .cc {
      width: 100%;
      height: 0;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      /deep/.el-collapse {
        width: 100%;
        padding: 10px 20px;
        box-sizing: border-box;
        border: none;
      }
      /deep/.el-collapse-item__header {
        font-weight: 600;
        font-size: 16px;
        padding: 0 20px;
        box-sizing: border-box;
      }
      /deep/.conn {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 60px;
        box-sizing: border-box;
        > .title {
          width: auto;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 8px;
          i {
            font-size: 20px;
            cursor: pointer;
          }
        }
        > .imgs {
          width: 60%;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 16px;
          > img {
            margin-right: 8px;
            margin-bottom: 8px;
          }
        }
      }
      > .buts {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin-top: 10px;
        > .but {
          width: auto;
          background-color: #3752ff;
          color: #fff;
          padding: 5px 60px;
          margin: 0 20px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      > .item {
        display: flex;
        align-items: flex-start;
        margin-top: 15px;
        > .title {
          width: 120px;
          font-size: 15px;
          display: flex;
          justify-content: flex-end;
          padding-right: 8px;
          box-sizing: border-box;
          > i {
            margin-right: 5px;
            color: #ff4800;
          }
          > .va {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            > span {
              font-size: 13px;
              color: #6e80f5;
            }
          }
        }
        > .val {
          width: 0;
          flex: 1;
          box-sizing: border-box;
          /deep/.el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 100%;
          }
          .el-radio {
            width: 120px;
          }
          > .show-img {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            > img {
              margin: 0 10px 10px 0;
            }
          }
          > .task-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            > .item {
              width: 70%;
              background-color: #ebebebb4;
              display: flex;
              flex-wrap: wrap;
              border-radius: 5px;
              margin-bottom: 10px;
              padding: 10px;
              box-sizing: border-box;
              > .it {
                width: 50%;
                display: flex;
                font-size: 14px;
                margin-bottom: 8px;
                > .ti {
                  width: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  font-weight: 600;
                  margin-right: 7px;
                }
              }
            }
          }
          > .radio-input {
            width: 100%;
            height: auto;
            padding: 10px;
            box-sizing: border-box;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            display: flex;
            flex-direction: column;

            > .to {
              width: 100%;
              display: flex;
              height: auto;
              flex-wrap: wrap;
              /deep/.el-radio-group {
                width: 100%;
              }
              /deep/.el-radio,
              /deep/.el-checkbox {
                width: 48%;
                margin: 6px 0;
              }
            }
            > .bu {
              width: 100%;
              height: auto;
              margin-top: 10px;
              display: flex;
              > .ti {
                font-size: 14px;
                margin-right: 5px;
              }
              > .va {
                width: 0;
                flex: 1;
                /deep/.el-textarea {
                  width: 100%;
                }
              }
            }
          }
          .pdf-files {
            display: flex;
            padding: 10px 0;
            > p {
              margin-right: 10px;
            }
            > div {
              display: flex;
              > a {
                margin-right: 10px;
              }
              i {
                color: #ff4800;
                cursor: pointer;
              }
            }
          }
          .upload-file {
            width: 160px;
            height: 160px;
            background-color: #f1f1f1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8a8a8a;
            font-size: 45px;
            img {
              width: 160px;
              height: 160px;
            }
          }

          .selct {
            width: 100%;
            > .el-select {
              width: 100%;
            }
          }

          .text {
            width: 100px;
            height: 100px;
            font-size: 13px;
            cursor: pointer;
            position: relative;
            margin-right: 10px;
            margin-bottom: 10px;

            i {
              font-size: 25px;
            }

            .mb {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              background-color: #0303036e;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.5;
              color: #fff;

              > i {
                font-size: 15px;
                margin: 0 5px;
              }
            }

            &:hover .mb {
              opacity: 1;
            }
          }
          .AA {
            display: flex;
            .btn {
              width: 100px;
              height: 50px;
            }
          }
          .imgOUter {
            display: flex;

            .roomImg {
              position: relative; /* 为绝对定位的 .actions 创建定位上下文 */
              display: inline-block; /* 允许元素并排放置 */
              margin: 10px; /* 自定义间距 */
              display: flex;
            }
            .roomImg img {
              display: block; /* 确保图片是块级元素，避免底部间隙 */
              width: 180px; /* 自适应宽度 */
              height: 180px; /* 自适应高度 */
            }

            .actions {
              display: none; /* 默认隐藏操作图标 */
              position: absolute; /* 绝对定位在父容器内 */
              top: 0; /* 放置在顶部 */
              left: 0; /* 放置在左边 */
              right: 0; /* 撑满宽度 */
              bottom: 0; /* 撑满高度 */
              background: rgba(0, 0, 0, 0.5); /* 半透明背景以提高可见性 */
              justify-content: center; /* 水平居中 */
              align-items: center; /* 垂直居中 */
              flex-direction: row; /* 水平排列 */
            }

            .roomImg:hover .actions {
              display: flex; /* 鼠标悬停时显示操作图标 */
              color: #fff;
              justify-content: space-around;
              font-size: 30px;
              border-radius: 10px;
              overflow: hidden;
            }
          }

          .upload-item {
            position: relative;
            display: inline-block;
            &:hover {
              .el-upload-list__item-actions {
                display: flex; /* 悬停时显示操作图标 */
              }
            }
          }

          // .el-upload-list__item-thumbnail {
          //   // width: 160px; /* 可根据需要调整 */
          //   // height: 90px; /* 可根据需要调整 */
          // }

          .el-upload-list__item-actions {
            display: none; /* 默认隐藏操作图标 */
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5); /* 背景增加一定透明度 */
            justify-content: center;
            align-items: center;
            // flex-direction: column; /* 垂直方向排列 */
          }
          .el-upload-list__item-delete {
            color: white; /* 使图标颜色为白色，以便在深色背景下清晰可见 */
            margin: 5px; /* 添加间距 */
            cursor: pointer; /* 鼠标悬浮时显示为可点击 */
            width: 20px;
            height: 20px;
          }
          .el-upload-list__item-preview {
            color: white; /* 使图标颜色为白色，以便在深色背景下清晰可见 */
            margin: 5px; /* 添加间距 */
            cursor: pointer; /* 鼠标悬浮时显示为可点击 */
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
