export function editBaseData() {
  return [
    {
      width: "70%",
      isNotNull: false,
      type: "imgs",
      title: "房间照片",
      key: "images",
      value: [],
      uploadData: {
        action: process.env.VUE_APP_BASE_API + "nursinghomes/file/uploadFile",
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "房间照片",
          key: "images",
        },
      },
    },
    {
      width: "40%",
      isNotNull: true,
      type: "input",
      title: "房间号",
      key: "roomNumber",
      value: "",
    },
  ];
}
