import { options } from "less";

/**
 * 界面基础数据
 * @returns
 */
export function baseData() {
  return {
    searchData: [
      {
        title: "",
        value: "",
        type: "input",
        key: "name",
      },
      {
        title: "",
        value: "",
        type: "select",
        key: "status",
        options: [
          { id: "待批准", value: "待批准" },
          { id: "已批准", value: "已批准" },
          { is: "已拒绝", value: "已拒绝" },
        ],
      },
    ],
    searchButton: [
      // {
      //   name: "新增",
      //   icon: "el-icon-circle-plus-outline",
      //   key: "addItemCollection",
      // },
    ],
    menuData: [
      {
        key: "status",
        title: "状态",
        width: "",
        isChangeColor: true,
      },
      {
        key: "employeeName",
        title: "员工名称",
        width: "",
      },
      {
        key: "content",
        title: "内容",
        width: "",
      },
      {
        key: "storeItemName",
        title: "物品",
        width: "",
      },
      {
        key: "number",
        title: "数量",
        width: "",
      },
     
      {
        key: "isReturn",
        title: "是否归还",
        width: "",
      },
      {
        key: "returnTime",
        title: "归还时间",
        width: "200",
      },
      
      {
        key: "images",
        title: "申请图片",
        width: "500",
        showImages: true,
      },
    ],
    tableButton: [
      { title: "批准", key: "ratify" },
      { title: "拒绝", key: "reject" },
    ],
  };
}

export function editBaseData() {
  return [
    {
      width: "40%",
      isNotNull: true,
      type: "input",
      title: "物品名称",
      key: "name",
      value: "",
    },
    {
      width: "40%",
      isNotNull: true,
      type: "input",
      title: "库存",
      key: "storageNumber",
      value: "",
    },
  ];
}
